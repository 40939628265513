// react
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// components
import { ClientPortal as ClientPortalPage, theme } from '@karehero/llama';

// store
import {
  useGetAllOrganizationAccountsQuery,
  useGetInviteEmailTemplateQuery,
  useGetSessionOrganizationQuery,
  useReinviteMemberMutation,
} from 'store/api/organization';
import { useLazyGetAllCareRecipientSummaryByAccountQuery } from 'store/api/careCircle';
import { useSendInternalReferralMutation } from 'store/api/referral';
import { newToast } from 'store/slices/toastSlice';
import { getAccount } from 'store/slices/iamSlice';
import { useSelector } from 'store';

// hooks
import { useCareProfileForm } from 'hooks';

// mixpanel
import { MixpanelEvent, useMixpanel, useWindowSize } from 'hooks';

// models
import { CareRecipientSummary, ReferClientRequest } from '@karehero/models';

// launch darkly
import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * ClientPortal shows the accounts and invited members of an organization
 */
const ClientPortal = () => {
  // stages
  const [selectedCareRecipient, setSelectedCareRecipient] =
    useState<CareRecipientSummary>({} as CareRecipientSummary);

  // hooks
  const dispatch = useDispatch();
  const { data: accounts } = useGetAllOrganizationAccountsQuery();
  const { data: organization } = useGetSessionOrganizationQuery();
  const { data: template } = useGetInviteEmailTemplateQuery();
  const [sendInternalReferral] = useSendInternalReferralMutation();
  const [reinviteMember] = useReinviteMemberMutation();
  const account = useSelector(getAccount);
  const { handlePrintPdf } = useCareProfileForm({
    categoryID: 'financial-status',
  });

  const [careRecipientSummaries] =
    useLazyGetAllCareRecipientSummaryByAccountQuery();

  const [width] = useWindowSize();
  const { track } = useMixpanel();
  const flags = useFlags();

  // effects
  useEffect(() => {
    track(MixpanelEvent.InternalPartnerReferralFormViewed, {});
  }, [track]);

  // callbacks
  const inviteClient = useCallback(
    (formData: ReferClientRequest) => {
      sendInternalReferral(formData);
      track(MixpanelEvent.InternalPartnerReferralFormSubmitted, {});
    },
    [track, sendInternalReferral],
  );

  // methods
  const handleResendInvite = useCallback(
    async (id?: string) => {
      if (!id) return;
      const res = await reinviteMember(id);

      if ('error' in res) {
        console.error(res.error);
        return;
      }
    },
    [reinviteMember],
  );

  const getCareRecipientShortlistData = useCallback(
    async (id: string) => {
      const res = await careRecipientSummaries(id || '');
      if (res.data) {
        setSelectedCareRecipient(res.data[0]);
        return res.data;
      }
      return [];
    },
    [careRecipientSummaries, setSelectedCareRecipient],
  );

  const onPrintPdf = useCallback(() => {
    handlePrintPdf(
      selectedCareRecipient.careRecipientFinancialAssessmentAnswers,
      selectedCareRecipient.fullName,
      true,
    );
  }, [handlePrintPdf, selectedCareRecipient]);

  return (
    <ClientPortalPage
      isLoading={!accounts || !account || !organization || !template}
      accounts={accounts}
      ownerAccount={account}
      organization={organization}
      emailTemplate={template || ''}
      inviteClient={inviteClient}
      resendInvite={handleResendInvite}
      isBackButton={false}
      notifyToast={(req: { title: string; description: string }) =>
        dispatch(newToast(req))
      }
      getCareRecipientShortlistData={getCareRecipientShortlistData}
      isMobile={width < theme.breakpoint.mdValue}
      isShortlistSummary={flags.shortlistSummary}
      onPrintPdf={onPrintPdf}
    />
  );
};

export default ClientPortal;
