import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { FieldType } from '@karehero/models';
// components
import { Button, ButtonSocial, Container, Logo, Title, } from "../../atoms";
import { EditorReveal } from "../../organisms";
import { HorizontalSplit } from "../../templates";
import { createFieldHelper } from "../../organisms/EditorFields/fieldHelper";
// assets
// @transform-path ../../../../../../src/assets/ribbon2.svg
import ribbon2 from "../../../../../../src/assets/ribbon2.svg";
/**
 * SignIn is the page where the user can sign in.
 */
export const SignIn = ({ onGoogleSignIn, onFacebookSignIn, onAppleSignIn, onEmailSignIn, message, isEmailSignInDisabled, }) => {
    return (_jsx(HorizontalSplit, { topContent: _jsxs(TopContent, { children: [_jsx(Logo, { size: 'lg' }), _jsx(RibbonWrapper, { children: _jsx("img", { src: ribbon2 }) })] }), bottomContent: _jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsxs(ContentWrapper, { children: [_jsx(StyledTitle, { children: "Care Simplified." }), message && _jsx(Message, { children: message })] }), _jsxs(ButtonContainer, { children: [_jsxs(ButtonSocialContainer, { children: [onAppleSignIn && (_jsx(ButtonSocial, { variant: 'apple', ariaLabel: 'sign in with Apple', onPress: onAppleSignIn, isFullWidth: true })), onGoogleSignIn && (_jsx(ButtonSocial, { variant: 'google', ariaLabel: 'sign in with Google', onPress: onGoogleSignIn, isFullWidth: true })), onFacebookSignIn && (_jsx(ButtonSocial, { variant: 'facebook', ariaLabel: 'sign in with Facebook', onPress: onFacebookSignIn, isFullWidth: true })), _jsx(EditorReveal, { onSubmit: (value) => onEmailSignIn(value === null || value === void 0 ? void 0 : value.email), revealLabel: 'Sign in with Email', submitLabel: 'Sign in', revealVariant: 'secondary', submitVariant: 'primary', revealIconLeft: 'envelope', isDisabled: isEmailSignInDisabled, fields: createFieldHelper({
                                            email: {
                                                label: 'Email',
                                                placeholder: 'email@example.com',
                                                isRequired: true,
                                                validationRules: [
                                                    {
                                                        type: 'email',
                                                    },
                                                ],
                                                field: {
                                                    type: FieldType.Email,
                                                    autoFocus: true,
                                                },
                                            },
                                        }) })] }), _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'register', isFullWidth: true, to: '/register' }, { children: "I don't have an account" }))] }), _jsxs(ContextContainer, { children: ["For any urgent issues, please email us at", ' ', _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'email support', to: 'mailto:support@karehero.com', isInline: true }, { children: "support@karehero.com" })), ' ', "or book a call", ' ', _jsx(Button, Object.assign({ variant: 'link', ariaLabel: 'book a call', to: '/book-call?category=karehero-call', isInline: true }, { children: "here" }))] })] }) })) }));
};
const StyledContainer = styled(Container) `
  background: ${(props) => props.theme.color.cards.yellow.tertiary};
  overflow-y: auto;
  height: 100%;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[8]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[16]};
  margin: ${(props) => props.theme.spacing[32]} 0;
`;
const Message = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.danger[40]};
  text-align: center;
  border: 1px solid ${(props) => props.theme.color.danger[40]};
  border-radius: 8px;
  padding: ${(props) => props.theme.spacing[8]};
  background: ${(props) => props.theme.color.danger[20]};
`;
const StyledTitle = styled(Title) `
  text-align: center;
  font: ${(props) => props.theme.typography.heading['5xl']};
  margin: 0;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[24]};
  margin-bottom: ${(props) => props.theme.spacing[64]};
`;
const ButtonSocialContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${(props) => props.theme.spacing[12]};
`;
const ContextContainer = styled.div `
  text-align: center;
`;
const TopContent = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.cards.yellow.tertiary};
  height: 100%;
`;
const RibbonWrapper = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  & > img {
    padding-top: ${(props) => props.theme.spacing[16]};
    width: 180vw;
  }
`;
SignIn.displayName = 'SignIn';
export default SignIn;
