import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useRef } from 'react';
import styled from 'styled-components';
// react-transition-group
import { CSSTransition, SwitchTransition } from 'react-transition-group';
/**
 * LoadingTransition displays a loading state until isLoading is false, then
 * fades into the content.
 */
export const LoadingTransition = ({ isLoading, loading = true, content, speed = 200, }) => {
    const nodeRef = useRef(null);
    return (_jsx(SwitchTransition, { children: _jsx(CSSTransition, Object.assign({ nodeRef: nodeRef, timeout: speed * 2, classNames: 'fade', unmountOnExit: true }, { children: _jsx(AnimatedOutlet, Object.assign({ "$speed": speed, ref: nodeRef }, { children: isLoading ? loading : content })) }), isLoading ? 'loading' : 'content') }));
};
const AnimatedOutlet = styled.div `
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
  align-items: inherit;
  justify-content: inherit;

  width: 100%;
  height: 100%;

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition-duration: ${(props) => props.$speed}ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition-duration: ${(props) => props.$speed}ms;
    transition-delay: 100ms;
  }
`;
LoadingTransition.displayName = 'LoadingTransition';
export default LoadingTransition;
