import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useRef } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// radix
import * as RadixCheckbox from '@radix-ui/react-checkbox';
// components
import { Icon } from "../Icon";
// utils
import { getColorContrast } from "../../../utils/color";
/**
 * Checkbox allows the user to select one or more items from a set.
 */
export const Checkbox = ({ className, id = 'checkbox', size = 'md', label, value, onChange, variant = 'default', isDisabled = false, icon, iconFill, }) => {
    // refs
    const ref = useRef(null);
    return (_jsxs(CheckboxRoot, Object.assign({ className: className, id: id, ref: ref, checked: value, onCheckedChange: onChange, "$size": size !== null && size !== void 0 ? size : 'md', "$variant": variant, disabled: isDisabled, "$isDisabled": isDisabled }, { children: [icon && (_jsx(IconWrapper, Object.assign({ "$background": iconFill }, { children: _jsx(Icon, { icon: icon, fontSize: 22 }) }))), _jsxs(CheckboxLabelWrapper, Object.assign({ "$size": size, "$variant": variant }, { children: [variant !== 'box-fill' && (_jsx(Wrapper, Object.assign({ id: `checkbox-${id}`, "$size": size !== null && size !== void 0 ? size : 'md', "$variant": variant, "$isDisabled": isDisabled }, { children: _jsx(CheckboxIndicator, Object.assign({ "$variant": variant, "$isDisabled": isDisabled }, { children: _jsx(Icon, { icon: 'check', type: 'fas', fontSize: size === 'sm' ? 12 : size === 'md' ? 18 : 18 }) })) }), `checkbox-${id}`)), label && (_jsx(Label, Object.assign({ htmlFor: id, size: size, "$isDisabled": isDisabled }, { children: label })))] }))] })));
};
const CheckboxRoot = styled(RadixCheckbox.Root) `
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  gap: ${(props) => props.theme.spacing[16]};
  ${(props) => {
    switch (props.$variant) {
        case 'default':
            return css ``;
        case 'box':
        case 'box-fill':
            return css `
          background: ${props.theme.color.neutral[10]};
          border: 1px solid ${props.theme.color.neutral[50]};
          border-radius: 8px;
          gap: 0;
        `;
    }
}};

  ${(props) => {
    if (props.checked) {
        switch (props.$variant) {
            case 'box-fill':
                return css `
            background: ${props.theme.color.cards.red.primary};
          `;
        }
    }
}}

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[30]};
      color: ${props.theme.color.neutral[50]};
      border: 1px solid ${props.theme.color.neutral[50]};
      cursor: default;
    `}

  transition-duration: 0.1s;
`;
const Wrapper = styled.div `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background: ${(props) => props.theme.color.neutral[10]};
  border: 1px solid ${(props) => props.theme.color.neutral[50]};
  border-radius: ${(props) => (props.$variant === 'circle' ? '50%' : '4px')};

  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          width: 16px;
          min-width: 16px;
          max-width: 16px;
          height: 16px;
          min-height: 16px;
          max-height: 16px;
        `;
        case 'md':
            return css `
          width: 20px;
          min-width: 20px;
          max-width: 20px;
          height: 20px;
          min-height: 20px;
          max-height: 20px;
        `;
        case 'lg':
            return css `
          width: 34px;
          min-width: 34px;
          max-width: 34px;
          height: 34px;
          min-height: 34px;
          max-height: 34px;
        `;
    }
}}

  transition-duration: 0.2s;
  &[data-state='checked'],
  &:hover {
    border: 1px solid
      ${(props) => props.$variant === 'circle'
    ? props.theme.color.success[50]
    : props.theme.color.accent.violetLight};
  }
  &:focus-visible {
    border: 1px solid
      ${(props) => props.$variant === 'circle'
    ? props.theme.color.success[50]
    : props.theme.color.accent.violetLight};
    outline: 3px solid ${(props) => props.theme.color.primary[20]};
  }

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[40]};
      color: ${props.theme.color.neutral[50]};
      border: 1px solid ${props.theme.color.neutral[50]};
      cursor: default;
    `}
`;
const CheckboxLabelWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[props.$size === 'sm' ? 8 : props.$size === 'md' ? 8 : 12]};

  ${(props) => {
    switch (props.$variant) {
        case 'default':
            return css ``;
        case 'box':
        case 'box-fill':
            return css `
          padding: ${props.theme.spacing[props.$size === 'sm' ? 24 : props.$size === 'md' ? 48 : 64]}
            ${props.theme.spacing[props.$size === 'sm' ? 16 : props.$size === 'md' ? 48 : 64]};
        `;
    }
}};
`;
const CheckboxIndicator = styled(RadixCheckbox.Indicator) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.$variant === 'circle'
    ? props.theme.color.success[50]
    : props.theme.color.accent.violetLight};
  color: ${(props) => props.theme.color.neutral[10]};

  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[60]};
    `}
`;
const Label = styled.label `
  user-select: none;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[90]};

  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          font: ${props.theme.typography.body.lg.regular};
        `;
        case 'md':
            return css `
          font: ${props.theme.typography.body.lg.regular};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.body.lg.regular};
          padding-top: 4px;
        `;
    }
}}

  ${(props) => props.$isDisabled &&
    css `
      color: ${props.theme.color.neutral[60]};
    `}
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 100%;
  ${(props) => props.$background &&
    css `
      background: ${props.$background};
      color: ${getColorContrast(props.$background || '#ffffff')};
    `}
`;
Checkbox.displayName = 'Checkbox';
export default Checkbox;
