import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, Container, Icon, Card } from "../../atoms";
import { FloatingActions } from "../../molecules";
/**
 * Displays the intro Page before user starts a form.
 */
export const FormIntro = ({ introPageItems, introCardDetails, title, backTo = '/care-profile', toAssistance, toForm, onNext, }) => {
    return (_jsx(PageWrapper, { children: _jsx(Container, Object.assign({ size: 'xl' }, { children: _jsxs(ContentWrapper, { children: [_jsx(ButtonWrapper, { children: _jsx(Button, Object.assign({ ariaLabel: 'back', variant: 'secondary', to: backTo, iconLeft: 'chevron-left', size: 'sm' }, { children: 'Back' })) }), _jsx(PageTitle, { children: title }), _jsx(ContentCard, Object.assign({ color: 'blue', isBordered: false, elevation: 0 }, { children: _jsx("table", { children: introPageItems.map(({ icon, text, iconColor }, index) => (_jsxs("tr", { children: [_jsx(IconCell, { children: _jsx(ColoredIcon, { icon: icon, iconColor: iconColor, fontSize: 30 }) }), _jsx(TextCell, { children: text })] }, index))) }) })), _jsx(InfoCard, Object.assign({ color: 'yellow', isBordered: false, elevation: 0 }, { children: _jsxs(InfoCardDetails, { children: [_jsx(Icon, { icon: 'info-circle', fontSize: 18 }), _jsxs(TextWrapper, { children: [_jsx(InfoCardTitle, { children: 'Did you know?' }), _jsx(InfoCardContent, { children: introCardDetails })] })] }) })), _jsx(FloatingActions, { actions: [
                            {
                                label: 'Get Assistance',
                                variant: 'secondary',
                                to: toAssistance,
                            },
                            {
                                label: 'Start Form',
                                to: toForm,
                                onPress: onNext,
                            },
                        ] })] }) })) }));
};
const ButtonWrapper = styled.div `
  width: fit-content;
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const PageWrapper = styled.div `
  padding: ${(props) => props.theme.spacing[24]};
  padding-bottom: ${(props) => props.theme.spacing[96]};
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const PageTitle = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.heading['3xl']};
`;
const IconCell = styled.td `
  padding-right: ${(props) => props.theme.spacing[12]};
`;
const ContentCard = styled(Card) `
  padding: ${(props) => props.theme.spacing[32]};
`;
const TextCell = styled.td `
  font: ${(props) => props.theme.typography.body.md.regular};
  padding: ${(props) => props.theme.spacing[12]} 0;
`;
const ColoredIcon = styled(Icon) `
  color: ${(props) => props.iconColor === 'blue'
    ? props.theme.color.actionPlanCards.blue.primary
    : props.iconColor === 'success'
        ? props.theme.color.success[40]
        : props.theme.color.primary[70]};
`;
const InfoCard = styled(Card) `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  padding: ${(props) => props.theme.spacing[16]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const InfoCardDetails = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  padding-left: ${(props) => props.theme.spacing[8]};
`;
const InfoCardTitle = styled.div `
  padding-bottom: ${(props) => props.theme.spacing[8]};
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['1xl']};
`;
const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const InfoCardContent = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
FormIntro.displayName = 'FormIntro';
export default FormIntro;
