import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
/**
 * Card is a container for content.
 */
export const Card = ({ className, elevation = 2, children, color = 'white', isFullWidth = false, isPaddingSm = false, isNoPadding = false, isTransparent = false, isBordered = true, as = 'div', }) => {
    return (_jsx(StyledCard, Object.assign({ className: className, "$elevation": elevation, "$isPaddingSm": isPaddingSm, "$isNoPadding": isNoPadding, "$isBordered": isBordered, "$isTransparent": isTransparent, "$isFullWidth": isFullWidth, "$color": color, as: as }, { children: children })));
};
const StyledCard = styled.div `
  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.elevation[props.$elevation]};
  border: ${(props) => props.$isBordered && css `1px solid ${props.theme.color.neutral[50]}`};
  box-sizing: border-box;
  text-decoration: none;
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};

  padding: ${(props) => props.theme.spacing[36]};
  ${(props) => props.$isPaddingSm &&
    css `
      padding: ${props.theme.spacing[16]};
    `};

  ${(props) => props.$color !== 'white' &&
    props.$color !== 'none' &&
    css `
      background: ${props.theme.color.cards[props.$color]
        .primary}${props.$isTransparent ? 'F2' : 'FF'};
      border: ${props.$isBordered
        ? `1px solid ${props.theme.color.cards[props.$color].secondary}`
        : 'none'};
      color: ${props.$isBordered &&
        `${props.theme.color.cards[props.$color].tertiary}`};
    `}

  ${(props) => props.$color === 'none' &&
    css `
      background: none;
    `}
  ${(props) => props.$color === 'white' &&
    css `
      border: ${props.$isBordered
        ? `1px solid ${props.theme.color.cards.green.secondary}`
        : 'none'};
      background: ${props.theme.color.neutral[20]};
      color: ${props.theme.color.neutral[100]};
    `}
  ${(props) => props.$color === 'green' &&
    css `
      background: ${props.theme.color.cards.green.secondary};
      color: ${props.theme.color.neutral[100]};
    `}
  ${(props) => props.$color === 'yellow' &&
    css `
      border: ${props.$isBordered
        ? `1px solid ${props.theme.color.cards.green.secondary}`
        : 'none'};
      background: ${(props) => props.theme.color.cards.buttermilk.primary};
      color: ${props.theme.color.neutral[100]};
    `}
  ${(props) => props.$isNoPadding &&
    css `
      padding: 0;
    `}
`;
Card.displayName = 'Card';
export default Card;
