// react
import { useEffect } from 'react';

// hooks
import { useCareProfileForm } from 'hooks';

// components
import {
  CareProfileForm as CareProfileFormPage,
  CareProfileRoot,
} from '@karehero/llama';

/**
 * CareProfileForm is the dashboard page.
 */
const CareProfileForm = () => {
  // hooks
  const {
    form,
    answers,
    setAnswers,
    category,
    sections,
    careRecipientFirstName,
    handlePrintPdf,
    toPrevious,
    title,
    permission,
    trackViewed,
    handleSave,
  } = useCareProfileForm({ categoryID: 'care-assessment' });

  // effects
  useEffect(trackViewed, [trackViewed]);

  return (
    <CareProfileRoot
      nextLabel='Save & Continue'
      onPrintPdf={handlePrintPdf}
      limit={category?.progress?.requiredCount || 0}
      progress={category?.progress?.answeredCount || 0}
      sections={sections}
      title={title}
      name={careRecipientFirstName}
      toExit={'/care-profile'}
      onNext={handleSave}
      toPrevious={toPrevious}
    >
      <CareProfileFormPage
        form={form}
        value={answers}
        onChange={setAnswers}
        careRecipientName={careRecipientFirstName}
        isDisabled={permission.isModify}
      />
    </CareProfileRoot>
  );
};

export default CareProfileForm;
