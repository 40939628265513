import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { useMemo, useState } from 'react';
import { theme } from "../../../theme";
// atoms
import { Button, Divider, Title, Icon, Dialog } from "../../atoms";
// organisms
import { Table } from "../../organisms";
import SimpleTable from "../../molecules/SimpleTable/SimpleTable";
/**
 * The PortalClientSummary component displays a summary of a care recipient's
 * care profile and shortlist and financial assessment.
 */
export const PortalClientSummary = ({ recipient, isMobile, onPrintPdf, }) => {
    var _a, _b;
    // states
    const [isFinancialSummaryDialogOpen, setIsFinancialSummaryDialogOpen] = useState(false);
    // memos
    const shortlistData = useMemo(() => (recipient === null || recipient === void 0 ? void 0 : recipient.careRecipientShortlistData) || [], [recipient]);
    const shortlistUrl = useMemo(() => {
        var _a;
        return (recipient === null || recipient === void 0 ? void 0 : recipient.careRecipientShortlistData)
            ? `https://caresourcer.com/uk/shortlist/${(_a = recipient.careRecipientShortlistData[0]) === null || _a === void 0 ? void 0 : _a.shortlistID}`
            : null;
    }, [recipient]);
    const careRecipientAssessmentDate = useMemo(() => {
        return new Date(recipient.careRecipientAssessmentDate).toLocaleDateString('en-GB');
    }, [recipient.careRecipientAssessmentDate]);
    const careRecipientFinancialAssessmentDate = useMemo(() => {
        return new Date(recipient.careRecipientFinancialAssessmentDate).toLocaleDateString('en-GB');
    }, [recipient.careRecipientFinancialAssessmentDate]);
    return (_jsxs(_Fragment, { children: [_jsxs(CareRecipientHeading, { children: [_jsx(CareRecipientLabel, { children: "Care recipient: " }), _jsx(CareRecipientName, { children: (recipient === null || recipient === void 0 ? void 0 : recipient.fullName) || 'Unknown' })] }), _jsx(Divider, { isOr: false, margin: 16 }), _jsxs(Summary, Object.assign({ "$isMobile": isMobile }, { children: [_jsxs("div", { children: [_jsxs(SummaryItemHeading, Object.assign({ "$isMobile": isMobile }, { children: [_jsxs(SummaryItemText, Object.assign({ "$isMobile": isMobile }, { children: [_jsx(Title, Object.assign({ size: 'sm', isNoMargin: isMobile }, { children: "Care Assessment" })), ' ', !isMobile && ' | ', recipient.careRecipientAssessmentStatus ? (recipient.careRecipientAssessmentStatus.toLowerCase() ===
                                                'complete' ? (_jsxs("div", { children: ["Complete ", careRecipientAssessmentDate] })) : (_jsx("div", { children: recipient.careRecipientAssessmentStatus }))) : (_jsx("div", { children: "Not Started" }))] })), !isMobile && shortlistUrl && (_jsx("div", Object.assign({ className: 'summary-item-actions' }, { children: _jsx(Button, Object.assign({ size: 'sm', ariaLabel: 'view shortlist', variant: 'secondary', to: shortlistUrl, target: '_blank' }, { children: "View Shortlist" })) })))] })), _jsxs(SummaryItemSubtitle, { children: ["KareHero have recommended the following care providers to support", ' ', recipient.firstName, "'s needs:"] }), _jsx(Table, { actions: [], isPaginated: false, isSearchable: false, isTotalRows: false, isBorderBottom: true, columns: [
                                    {
                                        id: 'name',
                                        header: 'Care Provider',
                                        accessorKey: 'name',
                                    },
                                    {
                                        id: 'status',
                                        header: 'Status',
                                        accessorKey: 'status',
                                        cell: (info) => (_jsxs(StatusCell, { children: [_jsx("span", { children: info.getValue() }), info.getValue() === 'Secured' && (_jsx(Icon, { icon: 'check', type: 'fas', size: '2xs', fill: theme.color.success[40] }))] })),
                                    },
                                ], rows: (_b = (_a = shortlistData[0]) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map((item) => ({
                                    name: item.name,
                                    status: item.regulatoryID == recipient.securedRegulatoryID
                                        ? 'Secured'
                                        : 'Shortlisted',
                                })) }), isMobile && shortlistUrl && (_jsxs("div", Object.assign({ className: 'summary-item-actions' }, { children: [_jsx("br", {}), _jsx(MobileButton, Object.assign({ size: 'sm', ariaLabel: 'view shortlist', variant: 'secondary', to: shortlistUrl, target: '_blank' }, { children: "View shortlist" })), _jsx(Divider, { isOr: false, margin: 16 })] })))] }), _jsx("div", Object.assign({ className: 'summary-item' }, { children: _jsxs(SummaryItemHeading, Object.assign({ "$isMobile": isMobile }, { children: [_jsxs(SummaryItemText, { children: [_jsx(Title, Object.assign({ size: 'sm', isNoMargin: isMobile }, { children: "Financial Assessment" })), !isMobile && ' | ', _jsx("div", { children: recipient.careRecipientFinancialAssessmentStatus ? (recipient.careRecipientFinancialAssessmentStatus.toLowerCase() ===
                                                'complete' ? (_jsxs("div", { children: ["Complete ", careRecipientFinancialAssessmentDate] })) : (_jsx("div", { children: recipient.careRecipientFinancialAssessmentStatus }))) : (_jsx("div", { children: "Not started" })) })] }), recipient.careRecipientFinancialAssessmentDate && (_jsx("div", Object.assign({ className: 'summary-item-actions' }, { children: _jsx(Button, Object.assign({ size: 'sm', ariaLabel: 'view financial summary', variant: 'secondary', onPress: () => setIsFinancialSummaryDialogOpen(true) }, { children: "View Financial Summary" })) })))] })) }))] })), _jsx(Dialog, Object.assign({ title: 'Client Financial Summary', isCloseButton: true, isOpen: isFinancialSummaryDialogOpen, setIsOpen: (isOpen) => setIsFinancialSummaryDialogOpen(isOpen), variant: 'dialog', minSize: 'lg' }, { children: _jsx(SimpleTable, { rows: recipient.careRecipientFinancialAssessmentAnswers, actions: [
                        {
                            label: 'Print',
                            onPress: onPrintPdf,
                        },
                    ] }) }))] }));
};
// Care Recipient
const CareRecipientHeading = styled.div `
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: ${(props) => props.theme.spacing[48]};
`;
const CareRecipientLabel = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.primary[80]};
`;
const CareRecipientName = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Summary = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => !props.$isMobile && props.theme.spacing[80]};
`;
const SummaryItemHeading = styled.div `
  display: flex;
  align-items: baseline;
  gap: ${(props) => (props.$isMobile ? '16px' : '8px')};

  justify-content: space-between;

  ${(props) => props.$isMobile &&
    css `
      margin-top: ${(props) => props.theme.spacing[16]};
      margin-bottom: ${(props) => props.theme.spacing[16]};
    `}
`;
const SummaryItemSubtitle = styled.div `
  font: ${(props) => props.theme.typography.body.lg.medium};
  color: ${(props) => props.theme.color.primary[80]};
  margin-bottom: ${(props) => props.theme.spacing[16]};
`;
const SummaryItemText = styled.div `
  display: flex;
  align-items: baseline;
  gap: 8px;

  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};

  ${(props) => props.$isMobile &&
    css `
      flex-direction: column;
      align-items: flex-start;
    `}
`;
const MobileButton = styled(Button) `
  width: 100%;
`;
const StatusCell = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
PortalClientSummary.displayName = 'PortalClientSummary';
export default PortalClientSummary;
