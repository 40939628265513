var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import React, { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// react-aria
import { mergeProps, useButton, useFocusRing } from 'react-aria';
// components
import { Icon } from "../Icon";
/**
 * Button allows the user to trigger an action or event, such as submitting a
 * form, opening a dialog, canceling an action, or performing a delete
 * operation.
 */
export const Button = (_a) => {
    var { className, size = 'md', variant = 'primary', isDisabled = false, isRectangle = false, isInline = false, isFullWidth = false, type = 'button', iconLeft, iconRight, iconType, ariaLabel, children, isDarkMode, to, target, isPreventDefault } = _a, props = __rest(_a, ["className", "size", "variant", "isDisabled", "isRectangle", "isInline", "isFullWidth", "type", "iconLeft", "iconRight", "iconType", "ariaLabel", "children", "isDarkMode", "to", "target", "isPreventDefault"]);
    // state
    const [isIconOnly, setIsIconOnly] = React.useState(false);
    // hooks
    const ref = useRef(null);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { buttonProps, isPressed } = useButton(Object.assign(Object.assign({}, props), { isDisabled, type }), ref);
    // memos
    const computedButtonProps = useMemo(() => {
        return mergeProps(Object.assign(Object.assign({}, buttonProps), { onClick: (e) => {
                var _a;
                if (isPreventDefault) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                (_a = buttonProps.onClick) === null || _a === void 0 ? void 0 : _a.call(buttonProps, e);
            } }), focusProps);
    }, [buttonProps, isPreventDefault]);
    // effects
    useEffect(() => {
        setIsIconOnly(!!(iconLeft && !children && !iconRight));
    }, [iconLeft, children, iconRight]);
    return (_jsxs(StyledButton, Object.assign({}, computedButtonProps, { to: to }, { className: className, ref: ref, "aria-label": ariaLabel, "$size": size, "$variant": variant, "$isRectangle": isRectangle, "$isFocusVisible": isFocusVisible, "$isPressed": isPressed, "$isIconOnly": isIconOnly, "$isInline": isInline, "$isFullWidth": isFullWidth, "$isDarkMode": isDarkMode, "$isDisabled": isDisabled, target: target, as: to ? Link : 'button' }, { children: [iconLeft && (_jsx(Icon, { icon: iconLeft, type: iconType, fontSize: size === 'sm' ? 12 : size === 'lg' ? 20 : 16 })), children && _jsx(StyledButtonLabel, { children: children }), iconRight && (_jsx(Icon, { icon: iconRight, type: iconType, fontSize: size === 'sm' ? 12 : size === 'lg' ? 20 : 16 }))] })));
};
const StyledButton = styled.button `
  // default styles
  display: ${(props) => (props.$isInline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  text-decoration: none;

  border: none;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: none;

  ${(props) => props.$isFullWidth &&
    css `
      width: 100%;
    `}

  &:disabled {
    pointer-events: none;
  }
  ${(props) => props.$isDisabled &&
    css `
      pointer-events: none;
    `}

  // prop - variant
  ${(props) => {
    switch (props.$variant) {
        case 'primary':
            return css `
          // default
          color: ${props.$isDarkMode
                ? props.theme.color.neutral[10]
                : props.theme.color.neutral[100]};
          background: ${props.$isDarkMode
                ? props.theme.color.primary[80]
                : props.theme.color.primary[30]};

          // hover
          &:hover {
            background: ${props.$isDarkMode
                ? props.theme.color.success[40]
                : props.theme.color.primary[60]};
          }

          // focused
          ${props.$isFocusVisible &&
                css `
            background: ${props.$isDarkMode
                    ? props.theme.color.success[40]
                    : props.theme.color.primary[60]};
            outline: 3px solid
              ${props.$isDarkMode
                    ? props.theme.color.primary[80]
                    : props.theme.color.primary[50]};
            outline-offset: 2px;
          `}

          // pressed
          ${props.$isPressed &&
                props.theme.pressed(css `
            background: ${props.$isDarkMode
                    ? props.theme.color.success[40]
                    : props.theme.color.primary[40]};
          `)}

          // disabled
          &:disabled {
            background: ${props.theme.color.neutral[40]};
            color: ${props.theme.color.neutral[50]};
          }
          ${props.$isDisabled &&
                css `
            background: ${props.theme.color.neutral[40]};
            color: ${props.theme.color.neutral[50]};
          `}
        `;
        case 'secondary':
            return css `
          // default
          color: ${props.theme.color.primary[100]};
          background: ${props.theme.color.neutral[10]};
          ${props.theme.innerBorder(`1px solid ${props.theme.color.primary[90]}`, props.$isRectangle ? (props.$size === 'sm' ? 4 : 8) : 9999)}

          // hover
          &:hover {
            background: ${props.theme.color.primary[10]};
          }

          // focused
          ${props.$isFocusVisible &&
                css `
            background: ${props.theme.color.primary[10]};
            outline: 3px solid ${props.theme.color.primary[20]};
          `}

          // pressed
          ${props.$isPressed &&
                props.theme.pressed(css `
            background: ${props.theme.color.primary[20]};
          `)}

          // disabled
          &:disabled {
            background: none;
            color: ${props.theme.color.neutral[50]};
            &:before {
              border: 1px solid ${props.theme.color.neutral[50]};
            }
            ${props.theme.innerBorder(`1px solid ${props.theme.color.neutral[50]}`)}
          }
        `;
        case 'link':
            return css `
          // default
          color: ${props.$isDarkMode
                ? props.theme.color.neutral[10]
                : props.theme.color.neutral[100]};
          background: transparent;
          text-decoration: underline;

          // hover
          &:hover {
            color: ${props.$isDarkMode
                ? props.theme.color.neutral[30]
                : props.theme.color.primary[30]};
          }

          // focused
          ${props.$isFocusVisible &&
                css `
            outline: 2px solid ${props.theme.color.primary[50]};
            outline-offset: 2px;
          `}

          // pressed

          // disabled
          &:disabled {
            color: ${props.theme.color.neutral[60]};
          }
        `;
    }
}}

  // prop - size
  font: ${(props) => props.theme.typography.button[props.$size]};

  // prop - isRectangle
  border-radius: ${(props) => (props.$isRectangle ? 8 : 9999)}px;
  ${(props) => {
    if (props.$size === 'sm') {
        return props.$isRectangle
            ? 'border-radius: 4px;'
            : 'border-radius: 9999px;';
    }
}}

  ${(props) => {
    if (props.$variant === 'link')
        return;
    const spacing = props.theme.spacing;
    switch (props.$size) {
        case 'sm':
            return props.$isIconOnly
                ? css `
              padding: ${spacing[4]} ${spacing[4]};
            `
                : css `
              padding: ${spacing[8]} ${spacing[16]};
            `;
        case 'md':
            return props.$isIconOnly
                ? css `
              padding: ${spacing[8]} ${spacing[8]};
            `
                : css `
              padding: ${spacing[12]} ${spacing[16]};
            `;
        case 'lg':
            return props.$isIconOnly
                ? css `
              padding: ${spacing[16]} ${spacing[16]};
            `
                : css `
              padding: ${spacing[16]} ${spacing[24]};
            `;
    }
}}
`;
const StyledButtonLabel = styled.div `
  white-space: nowrap;
`;
Button.componentName = 'Button';
export default Button;
