import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Icon } from "../../../atoms";
import { Chat, Launcher } from "../..";
// assets
// @transform-path ../../../../../../../src/assets/loader/keni.svg
import keni from "../../../../../../../src/assets/loader/keni.svg";
/**
 * Chat a chat widget.
 */
export const ChatFAB = ({ className, messages, onSend, onReset, isTyping, isMobile, isApple, offsetY, }) => {
    // state
    const [isOpen, setIsOpen] = useState(false);
    // refs
    const ref = useRef(null);
    // effects
    useEffect(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({});
    }, [messages, isTyping, isOpen]);
    return (_jsx(Wrapper, Object.assign({ className: className, "$isMobile": isMobile, "$isApple": isApple, "$offsetY": offsetY }, { children: isOpen ? (_jsxs(ChatBody, { children: [_jsxs(Header, { children: [_jsxs(HeaderItem, { children: [_jsx(Keni, { children: _jsx("img", { src: keni, alt: 'Keni' }) }), _jsx(Name, { children: "KareHero AI" })] }), _jsx(HeaderItem, { children: _jsx(CloseButton, Object.assign({ onClick: () => setIsOpen(false) }, { children: _jsx(Icon, { icon: 'xmark', fontSize: 24 }) })) })] }), _jsx(Chat, { messages: messages, isTyping: isTyping, onSend: onSend, onReset: onReset })] })) : (_jsx(Launcher, { isVisible: true, isApple: true, isOpen: isOpen, setIsOpen: setIsOpen })) })));
};
const Wrapper = styled.div `
  position: fixed;
  bottom: 0px;
  right: 0px;
  ${(props) => props.$isMobile &&
    css `
      bottom: 70px;
    `}
  ${(props) => props.$isApple &&
    css `
      bottom: 91px;
    `}
  z-index: 100000;

  padding: ${(props) => props.theme.spacing[12]};
`;
const ChatBody = styled.div `
  display: inline-flex;
  flex-direction: column;

  overflow: hidden;

  background: ${(props) => props.theme.color.neutral[10]};
  border-radius: 12px;
  box-shadow: ${(props) => props.theme.elevation[2]};
  font: ${(props) => props.theme.typography.body.sm.regular};
  width: 100%;
  max-width: 400px;
  height: 600px;
  max-height: 600px;
`;
const Header = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.color.primary[60]};
  gap: ${(props) => props.theme.spacing[12]};
  color: ${(props) => props.theme.color.neutral[10]};
  padding: ${(props) => props.theme.spacing[8]};
  padding-right: ${(props) => props.theme.spacing[16]};
`;
const HeaderItem = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
`;
const Keni = styled.div `
  width: 42px;
  height: 42px;

  & img {
    width: 100%;
    height: 100%;
  }
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
`;
const CloseButton = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.neutral[10]};

  transition-duration: 200ms;
  &:hover {
    color: ${(props) => props.theme.color.neutral[20]};
  }
`;
ChatFAB.displayName = 'ChatFAB';
export default ChatFAB;
