import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { Card, Icon } from "../../atoms";
const CardWithIcon = ({ stepLabel, subTitle, icon, content, }) => {
    return (_jsx(Card, Object.assign({ color: 'none', elevation: 0, isBordered: false, isPaddingSm: true }, { children: _jsxs(Wrapper, { children: [_jsxs(IconWithLabelWrapper, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { icon: icon, fontSize: 48 }) }), _jsx(StepLabel, { children: stepLabel })] }), _jsx(SubTitle, { children: subTitle }), _jsx(Content, { children: content })] }) })));
};
/**
 * ReferralProcess describes the steps for a partner making a referral
 */
export const ReferralProcess = ({ className }) => {
    return (_jsxs(CardContainer, Object.assign({ className: className }, { children: [_jsx(CardWithIcon, { icon: 'memo', stepLabel: 'Step 1', subTitle: 'Refer your client', content: 'Invite your client to KareHero by completing the form below' }), _jsx(CardWithIcon, { icon: 'envelope', stepLabel: 'Step 2', subTitle: 'Client receives invite', content: 'Personalised email invite gives access to KareHero and schedules an initial consultation with the care team' }), _jsx(CardWithIcon, { icon: 'check-circle', stepLabel: 'Step 3', subTitle: 'Track & Monitor', content: "Track your client's registration, monitor their needs, and access resources via your Partner Portal" })] })));
};
const CardContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoint.sm(css `
      flex-direction: row;
      align-items: flex-start;
    `)}
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.primary[80]};
  text-align: left;
`;
const StepLabel = styled.div `
  font: ${(props) => props.theme.typography.body.md.medium};
  text-align: left;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  max-width: 250px;
  align-items: left;
`;
const IconWithLabelWrapper = styled.div `
  color: ${(props) => props.theme.color.cards.red.tertiary};
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const IconWrapper = styled.div `
  border-radius: 99999px;
  align-items: left;
`;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: left;
  align-items: left;
`;
ReferralProcess.displayName = 'ReferralProcess';
export default ReferralProcess;
