// react
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useActionPlan, useCareProfile } from 'hooks';

// components
import { CareProfileRoot, SuccessContent } from '@karehero/llama';

/**
 * CareProfileComplete is the dashboard page.
 */
const CareProfileComplete = () => {
  // hooks
  const navigate = useNavigate();
  const {
    handlePrintPdf,
    category,
    sections,
    toLastFormPage,
    careRecipientFirstName,
    title,
    handleSubmit,
  } = useCareProfile({
    categoryID: 'care-assessment',
  });

  const { markActionComplete } = useActionPlan();

  const handleNext = useCallback(() => {
    handleSubmit();
    markActionComplete('tell-us-about-your-loved-one-care-plan');
    navigate('/book-call?category=find-care');
  }, [handleSubmit, navigate, markActionComplete]);

  return (
    <CareProfileRoot
      nextLabel='Submit Form'
      onPrintPdf={handlePrintPdf}
      title={title}
      limit={category?.progress?.requiredCount || 0}
      progress={category?.progress?.answeredCount || 0}
      sections={sections}
      name={careRecipientFirstName}
      toExit='/care-profile'
      onNext={handleNext}
      toPrevious={toLastFormPage}
      isNextDisabled={!category?.progress?.isComplete}
    >
      <SuccessContent
        header='Care Assessment'
        cardHeader='Your next steps'
        isComplete={category?.progress?.isComplete || false}
        fixtures={[
          {
            icon: 'check-circle',
            text: 'Review your Care Assessment answers to ensure all the needs captured are correct',
            iconColor: 'success' as 'success',
          },
          {
            icon: 'user',
            text: 'Once submitted you can chat to your Care Expert regarding your Care Assessment',
            iconColor: 'blue' as 'blue',
          },
          {
            icon: 'file-contract',
            text: 'Your Care Expert will now provide a care search shortlist tailored by your Care Assessment',
            iconColor: 'primary' as 'primary',
          },
        ]}
      />
    </CareProfileRoot>
  );
};

export default CareProfileComplete;
