// react
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useActionPlan, useCareProfile } from 'hooks';

// components
import { CareProfileRoot, SuccessContent } from '@karehero/llama';

/**
 * AttendanceAllowanceComplete is the dashboard page.
 */
const AttendanceAllowanceComplete = () => {
  // hooks
  const navigate = useNavigate();
  const {
    category,
    sections,
    careRecipientFirstName,
    toLastFormPage,
    title,
    handleSubmit,
  } = useCareProfile({
    categoryID: 'attendance-allowance',
  });

  const { markActionComplete } = useActionPlan();

  // methods
  const handleNext = useCallback(() => {
    handleSubmit();
    markActionComplete('claim-up-to-6k-per-year');
    navigate(`/${category?.id}/success`);
  }, [handleSubmit, navigate, category, markActionComplete]);

  return (
    <CareProfileRoot
      backLabel='Back'
      nextLabel='Submit form'
      limit={category?.progress?.requiredCount || 0}
      progress={category?.progress?.answeredCount || 0}
      sections={sections}
      name={careRecipientFirstName}
      title={title}
      toExit='/care-profile'
      onNext={handleNext}
      toPrevious={toLastFormPage}
      isNextDisabled={!category?.progress?.isComplete}
    >
      <SuccessContent
        header='Attendance Allowance'
        cardHeader='Your next steps'
        isComplete={category?.progress?.isComplete || false}
        fixtures={[
          {
            icon: 'check-circle',
            text: 'Review your Attendance Allowance answers to ensure all the needs captured are correct',
            iconColor: 'success' as 'success',
          },
          {
            icon: 'user',
            text: 'Once submitted you can chat to your Care Expert regarding your Attendance Allowance',
            iconColor: 'blue' as 'blue',
          },
        ]}
      />
    </CareProfileRoot>
  );
};

export default AttendanceAllowanceComplete;
