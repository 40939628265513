import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Loading as LoadingPage } from "../Loading/Loading";
import BackButton from "../../molecules/BackButton/BackButton";
// theme
import { theme } from "../../../theme";
/**
 * Calendly is a component that displays a Hubspot contact form.
 */
export const Calendly = ({ baseURL, backgroundColor, isHideGdprBanner = false, primaryColor = theme.color.primary[30], textColor = theme.color.cards.green.tertiary, calendlyCallDetails, isLoading, isBackButton = false, }) => {
    // memos
    const dataURL = useMemo(() => {
        const calendlyURL = new URL(baseURL);
        const queryParams = new URLSearchParams({
            text_color: (textColor === null || textColor === void 0 ? void 0 : textColor.replace('#', '')) || '',
            primary_color: (primaryColor === null || primaryColor === void 0 ? void 0 : primaryColor.replace('#', '')) || '',
        });
        if (calendlyCallDetails) {
            if (calendlyCallDetails.name) {
                queryParams.set('name', calendlyCallDetails.name);
            }
            if (calendlyCallDetails.kindeEmail) {
                queryParams.set('email', calendlyCallDetails.kindeEmail);
            }
            if (calendlyCallDetails.phoneNumber) {
                queryParams.set('a1', `+44${calendlyCallDetails.phoneNumber}`);
            }
            if (calendlyCallDetails.reasonForCall) {
                queryParams.set('a2', calendlyCallDetails.reasonForCall);
            }
            if (calendlyCallDetails.note) {
                queryParams.set('a3', calendlyCallDetails.note);
            }
            if (calendlyCallDetails.careCircleMembers) {
                queryParams.set('guests', calendlyCallDetails.careCircleMembers.join(','));
            }
        }
        if (isHideGdprBanner)
            queryParams.set('hide_gdpr_banner', '0');
        if (backgroundColor) {
            queryParams.set('background_color', backgroundColor.replace('#', ''));
        }
        calendlyURL.search = queryParams.toString().replace(/\+/g, '%20');
        return calendlyURL.toString();
    }, [
        backgroundColor,
        isHideGdprBanner,
        textColor,
        primaryColor,
        calendlyCallDetails,
    ]);
    // effects
    useEffect(() => {
        if (isLoading)
            return;
        const calendlyScript = document.createElement('script');
        calendlyScript.src =
            'https://assets.calendly.com/assets/external/widget.js';
        calendlyScript.type = 'text/javascript';
        calendlyScript.async = true;
        document.body.appendChild(calendlyScript);
    }, [isLoading]);
    const isCalendlyEvent = useCallback((e) => e.origin === 'https://calendly.com' &&
        e.data.event &&
        e.data.event.indexOf('calendly.') === 0, []);
    useEffect(() => {
        if (isLoading)
            return;
        const listener = (e) => {
            if (!isCalendlyEvent(e))
                return;
        };
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, [isLoading, isCalendlyEvent]);
    return (_jsxs(Wrapper, { children: [isBackButton && _jsx(StyledBackButton, {}), isLoading ? (_jsx(LoadingPage, {})) : (_jsx(StyledCalendly, { className: 'calendly-inline-widget', "data-url": dataURL }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;
const StyledCalendly = styled.div `
  min-height: 320px;
  flex: 1;
`;
const StyledBackButton = styled(BackButton) `
  margin: ${(props) => props.theme.spacing[36]};
`;
Calendly.displayName = 'Calendly';
export default Calendly;
