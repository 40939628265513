// components
import { FormIntro as CarePlanStartPage } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

/**
 * CarePlan shows the CarePlanPage component.
 */
const CarePlan = () => {
  // hooks
  const { handleStart, toFirstFormPage } = useCareProfile({
    categoryID: 'care-assessment',
  });

  return (
    <CarePlanStartPage
      introPageItems={[
        {
          icon: 'file-contract',
          text: 'Answer care and health questions about the person requiring care',
          iconColor: 'blue' as 'blue',
        },
        {
          icon: 'check-circle',
          text: 'Our experts will review and research suitable care services',
          iconColor: 'success' as 'success',
        },
        {
          icon: 'home',
          text: "We'll send you a Shortlist of services, and help you secure your top choice",
          iconColor: 'primary' as 'primary',
        },
      ]}
      introCardDetails={
        "We've helped thousands of families claim funding they didn't realise they were eligible for"
      }
      title={'Get expert help to secure the best care service'}
      toAssistance={'/book-call'}
      toForm={toFirstFormPage}
      onNext={handleStart}
    />
  );
};

export default CarePlan;
