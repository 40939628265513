import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// models
import { FieldType } from '@karehero/models';
// components
import { Card, Title } from "../../atoms";
import { createFieldHelper } from "../EditorFields/fieldHelper";
import { EditorFields } from "../EditorFields/EditorFields";
// theme
import { breakpointValues } from "../../../theme/breakpoint";
// hooks
import { useValidator, useWindowSize } from "../../../hooks";
const nameFields = createFieldHelper({
    firstName: {
        label: 'First Name',
        placeholder: 'Joe',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'name',
            },
            {
                type: 'min-length',
                value: 2,
            },
        ],
    },
    lastName: {
        label: 'Last Name',
        placeholder: 'Bloggs',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'name',
            },
            {
                type: 'min-length',
                value: 2,
            },
        ],
    },
});
const contactFields = createFieldHelper({
    email: {
        label: 'Email',
        placeholder: 'joe.bloggs@example.com',
        isRequired: true,
        field: {
            type: FieldType.Email,
        },
        validationRules: [{ type: 'email' }],
    },
    phoneNumber: {
        label: 'Phone Number',
        placeholder: '07123456789',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [{ type: 'phone' }],
    },
});
const recipientDetails = createFieldHelper({
    nameOfPersonReceivingCare: {
        label: 'Name of person receiving care',
        placeholder: 'Simon',
        isRequired: true,
        field: {
            type: FieldType.Text,
        },
        validationRules: [
            {
                type: 'name',
            },
            {
                type: 'min-length',
                value: 2,
            },
        ],
    },
    contextAboutSituation: {
        label: `Context about the situation (KareHero can also cover your client's Annual Review - please share their Annual Review date below if available)`,
        placeholder: "Share any relevant details about your client's current situation",
        isRequired: true,
        field: {
            type: FieldType.TextArea,
        },
        validationRules: [
            {
                type: 'min-length',
                value: 1,
            },
        ],
    },
});
/**
 * SjpClientForm renders the account settings page.
 */
export const SjpClientForm = ({ value, setValue, noText, setIsValid, }) => {
    // hooks
    const [width] = useWindowSize();
    const { createSetIsValid } = useValidator(setIsValid);
    const isMobile = useMemo(() => width < breakpointValues.mdValue, [width]);
    return (_jsxs(Wrapper, { children: [!noText && _jsx(Title, Object.assign({ isNoMargin: true }, { children: "Tell us about your client" })), !noText && (_jsx(Description, { children: "KareHero can support clients and their families at any stage of the care journey, whether actively searching for care, or just thinking about the future." })), _jsx(Card, Object.assign({ color: 'blue', isBordered: false, isPaddingSm: true }, { children: _jsxs(FieldWrapper, { children: [_jsx(EditorFields, { fields: nameFields, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), f)), isRow: !isMobile, setIsValid: createSetIsValid('nameFields') }), _jsx(EditorFields, { fields: contactFields, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), f)), isRow: !isMobile, setIsValid: createSetIsValid('contactFields') }), _jsx(EditorFields, { fields: recipientDetails, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), f)), setIsValid: createSetIsValid('recipientDetails') })] }) }))] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const FieldWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
SjpClientForm.displayName = 'SjpClientForm';
export default SjpClientForm;
