import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export const HorizontalSplit = ({ topContent, bottomContent, }) => {
    return (_jsxs(Wrapper, { children: [_jsx(TopContainer, { children: topContent }), _jsx(BottomContainer, { children: bottomContent })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const TopContainer = styled.div `
  flex: 1;
`;
const BottomContainer = styled.div `
  flex: 1;
`;
HorizontalSplit.displayName = 'HorizontalSplit';
export default HorizontalSplit;
