// react
import { useMemo } from 'react';

// components
import { Messenger as MessengerPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { useGetHubspotIdentificationTokenQuery } from 'store/api/iam';

// hooks
import { useChat } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

// sentry
import * as Sentry from '@sentry/react';

/**
 * Messenger shows the chat interface.
 */
const Messenger = () => {
  // hooks
  const account = useSelector(getAccount);
  const { messages, sendMessage, isChatLoading, createChat } = useChat();
  const flags = useFlags();
  const { data: hubspotIdentificationToken } =
    useGetHubspotIdentificationTokenQuery();

  // memos
  const isHubspot = useMemo(
    () =>
      account?.kindeEmail &&
      hubspotIdentificationToken &&
      hubspotIdentificationToken?.token !== '' &&
      hubspotIdentificationToken?.token !== 'invalid',
    [account, hubspotIdentificationToken],
  );

  if (!isHubspot) {
    Sentry.captureException(
      new Error(
        `Invalid or empty HubSpot Identification Token for email: ${account?.kindeEmail}`,
      ),
    );
  }

  return (
    <MessengerPage
      kindeEmail={account?.kindeEmail}
      messages={messages}
      onSend={sendMessage}
      onReset={async () => {
        createChat();
      }}
      isTyping={isChatLoading}
      isKareheroAI={flags?.kareheroAi}
      hubspotIdentificationToken={hubspotIdentificationToken?.token}
      account={account}
    />
  );
};

export default Messenger;
