import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
/**
 * TypingDots shows typing dots.
 */
export const TypingDots = ({}) => {
    return (_jsxs(Wrapper, { children: [_jsx(Dot, { className: 'dot1' }), _jsx(Dot, { className: 'dot2' }), _jsx(Dot, { className: 'dot3' })] }));
};
const Wrapper = styled.div `
  display: flex;
  padding: 5px 6px;
  gap: 4px;
  & .dot1 {
    animation-delay: 0s;
  }
  & .dot2 {
    animation-delay: 0.2s;
  }
  & .dot3 {
    animation-delay: 0.4s;
  }
`;
const Dot = styled.div `
  width: 8px;
  height: 8px;
  border-radius: 99999px;
  opacity: 0;
  animation: loadingFade 1s infinite;
  background: rgba(0, 0, 0, 0.8);

  @keyframes loadingFade {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      opacity: 0.5;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }
`;
export default TypingDots;
