import api from '.';
import {
  CareCircle,
  CareCircleMember,
  CareCircleMemberInvite,
  CareRecipientSummary,
  Message,
} from '@karehero/models';

export const careCircleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<CareCircle[], void>({
      query: () => '/care-circle',
      providesTags: ['CareCircles'],
    }),
    getCareCircle: builder.query<CareCircle, string>({
      query: (id) => ({ url: `care-circle/${id}` }),
      providesTags: ['CareCircles'],
    }),
    getSessionCareCircle: builder.query<CareCircle, void>({
      query: () => '/care-circle/session',
      providesTags: ['CareCircles'],
    }),
    getSessionCareCircleMember: builder.query<CareCircleMember, void>({
      query: () => '/care-circle/member',
      providesTags: ['CareCircles'],
    }),
    getByAccount: builder.query<CareCircle[], void>({
      query: () => '/care-circle/account',
      providesTags: ['CareCircles'],
    }),
    getAllCareRecipientSummaryByAccount: builder.query<
      CareRecipientSummary[],
      string
    >({
      query: (accountId: string) =>
        `/care-circle/account/${accountId}/recipient-summaries`,
      keepUnusedDataFor: 0,
    }),
    switchCareCircle: builder.mutation<CareCircle, string>({
      query: (id) => ({
        url: `/care-circle/${id}/switch`,
        method: 'POST',
      }),
      invalidatesTags: ['CareCircles'],
    }),
    inviteCareCircle: builder.mutation<Message, CareCircleMemberInvite>({
      query: (payload) => ({
        url: `/care-circle/invite`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['CareCircles'],
    }),
    createMember: builder.mutation<CareCircle, CareCircleMember>({
      query: ({ careCircleID, account }) => ({
        url: `/care-circle/${careCircleID}/member`,
        method: 'POST',
        body: account,
      }),
      invalidatesTags: ['CareCircles'],
    }),
    updateMember: builder.mutation<CareCircleMember, CareCircleMember>({
      query: (careCircleMember) => ({
        url: `/care-circle/member`,
        method: 'PUT',
        body: careCircleMember,
      }),
      invalidatesTags: ['CareCircles'],
    }),
  }),
});

export const {
  useGetAllQuery,
  useGetCareCircleQuery,
  useGetSessionCareCircleQuery,
  useGetSessionCareCircleMemberQuery,
  useLazyGetCareCircleQuery,
  useLazyGetAllCareRecipientSummaryByAccountQuery,
  useSwitchCareCircleMutation,
  useInviteCareCircleMutation,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useLazyGetByAccountQuery,
} = careCircleApi;

export default careCircleApi;
