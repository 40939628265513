import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
// components
import { CarouselIndicator, Container, Title } from "../../atoms";
import { Icon } from "../../atoms/Icon";
import { HorizontalSplit } from "../../templates";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
// theme
import theme from "../../../theme";
// assets
// @transform-path ../../../../../../src/assets/ribbon.svg
import ribbon from "../../../../../../src/assets/ribbon.svg";
const iconConfig = [
    {
        icon: 'hand-holding-heart',
        fill: theme.color.actionPlanCards.red.secondary,
        ribbonPosition: ['690px', '-550px'],
    },
    {
        icon: 'money-bills',
        fill: theme.color.actionPlanCards.green.secondary,
        ribbonPosition: ['0px', '0px'],
    },
    {
        icon: 'user-headset',
        fill: theme.color.actionPlanCards.yellow.secondary,
        ribbonPosition: ['-690px', '-550px'],
    },
];
/**
 * OnboardingCarousel renders the carousel onboarding views.
 */
export const OnboardingCarousel = toGenericOnboarding(({ config, currentIndex = 0, setIsNextDisabled, }) => {
    var _a, _b;
    // effects
    useEffect(() => {
        setIsNextDisabled(false);
    }, [setIsNextDisabled]);
    return (_jsx(Main, { children: _jsx(HorizontalSplit, { topContent: _jsxs(IconWrapper, { children: [_jsx(RibbonWrapper, Object.assign({ position: iconConfig[currentIndex % 3].ribbonPosition }, { children: _jsx("img", { src: ribbon, alt: 'ribbon' }) })), _jsx(Icon, { icon: iconConfig[currentIndex % 3].icon, fill: iconConfig[currentIndex % 3].fill, fontSize: 80 })] }), bottomContent: _jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsxs(MainWrapper, { children: [_jsx(StyledTitle, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.subTitle }), _jsx(Text, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.text })] }), _jsx(IndicatorWrapper, { children: _jsx(CarouselIndicator, { total: config.length, index: currentIndex, color: 'dark' }) })] }) })) }) }));
});
const StyledContainer = styled(Container) `
  overflow-x: hidden;
`;
const Main = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[24]};
  height: 100%;

  ${(props) => props.theme.breakpoint.md(css `
      justify-content: flex-start;
      gap: ${props.theme.spacing[24]};
    `)};
`;
const MainWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[32]};
  padding-left: ${(props) => props.theme.spacing[24]};
  padding-right: ${(props) => props.theme.spacing[24]};
  flex: 1;
`;
const IndicatorWrapper = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const StyledTitle = styled(Title) `
  margin: 0;
  color: ${(props) => props.theme.color.primary[80]};
  text-align: center;
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.primary[80]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${(props) => props.theme.typography.body.xl.regular};
    `)}
`;
const IconWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding-bottom: ${(props) => props.theme.spacing[32]};

  & > div:last-child {
    box-shadow: ${(props) => props.theme.elevation[4]};
  }
`;
const RibbonWrapper = styled.div `
  position: absolute;
  top: calc(0px + ${(props) => props.position[1] || '0px'});
  left: calc(50% + ${(props) => props.position[0] || '0px'});
  transform: translateX(-50%);
  transition: all 0.8s ease-in-out;
  z-index: -1;
  & > img {
    padding-top: ${(props) => props.theme.spacing[16]};
    width: 6300px;
  }
`;
OnboardingCarousel.displayName = 'OnboardingCarousel';
export default toGenericOnboarding(OnboardingCarousel);
