import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Card, EmailPreview, TextArea } from "../../atoms";
/**
 * InviteEmailPreview
 */
export const InviteEmailPreview = ({ emailTemplate, personalizedMessage, defaultPersonalizedMessage, setPersonalizedMessage, clientName, inviterName, }) => {
    // memos
    const msg = useMemo(() => personalizedMessage === undefined
        ? defaultPersonalizedMessage
        : personalizedMessage, [personalizedMessage, defaultPersonalizedMessage]);
    const generated = useMemo(() => {
        return (emailTemplate || '')
            .replace('{{.PersonalizedMessage}}', msg || '')
            .replace('<img src="cid:qr_code0.png" alt="register QR code" width="150px" height="150px" />', '<div style="width: 150px; height: 150px; background: #eee; border-radius: 8px;"></div>');
    }, [msg]);
    const subject = useMemo(() => `${clientName} here's your invite from ${inviterName} to use KareHero: Care Concierge in your pocket`, [clientName, inviterName]);
    useEffect(() => {
        if (personalizedMessage === undefined) {
            setPersonalizedMessage(defaultPersonalizedMessage || '');
        }
    }, [defaultPersonalizedMessage, setPersonalizedMessage, personalizedMessage]);
    return (_jsxs(Wrapper, { children: [_jsxs(Section, { children: [_jsx(SubTitle, { children: "Add Personalized Note" }), _jsx(Card, Object.assign({ color: 'blue', isBordered: false, isPaddingSm: true }, { children: _jsx(TextArea, { size: 'md', value: msg, onChange: setPersonalizedMessage }) }))] }), _jsxs(Section, { children: [_jsx(SubTitle, { children: "Email Preview" }), _jsx(EmailPreview, { subject: subject, html: generated })] })] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  width: 100%;
  height: 100%;
  padding: ${(props) => props.theme.spacing[20]};
  overflow-y: auto;
`;
const Section = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.primary[80]};
  text-align: left;
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
export default InviteEmailPreview;
