import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Background, Memphis } from "../../atoms";
// hooks
import { useWindowSizeBreakpoints } from "../../../hooks";
// theme
import theme from "../../../theme";
// assets
// @transform-path ../../../../../../src/assets/dashboard/header.jpg
import headerImage from "../../../../../../src/assets/dashboard/header.jpg";
/**
 * MemphisHeader displays the Memphis header.
 */
export const MemphisHeader = ({ className, variant = 'happy-family-1', }) => {
    // hooks
    const { isMd } = useWindowSizeBreakpoints();
    return (_jsx(Background, Object.assign({ className: className }, { children: _jsx(HeaderWrapper, { children: _jsx(Memphis, { variant: variant }) }) })));
};
const HeaderWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  max-height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[32]};
  padding-bottom: 0;

  > *:last-child {
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: auto;
    overflow: hidden;
  }

  ${(props) => props.theme.breakpoint.md(css `
      padding: ${props.theme.spacing[64]};
      padding-bottom: 0;
    `)}
`;
MemphisHeader.displayName = 'MemphisHeader';
export default MemphisHeader;
