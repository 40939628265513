// react
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// hooks
import { useActionPlan, useCareProfile } from 'hooks';

// components
import { CareProfileRoot, SuccessContent } from '@karehero/llama';

/**
 * FinancialStatusComplete is the page displayed for completing the financial status process.
 */
const FinancialStatusComplete = () => {
  // hooks
  const navigate = useNavigate();
  const {
    careRecipientFirstName,
    handlePrintPdf,
    sections,
    toLastFormPage,
    title,
    category,
    handleSubmit,
  } = useCareProfile({ categoryID: 'financial-status' });

  const { markActionComplete } = useActionPlan();

  // methods
  const handleNext = useCallback(() => {
    handleSubmit();
    markActionComplete('tell-us-about-your-loved-one-financial-status');
    navigate('/action-plan/get-my-funding-guide');
  }, [handleSubmit, navigate, markActionComplete]);

  return (
    <CareProfileRoot
      title={title}
      nextLabel='Submit Form'
      onPrintPdf={handlePrintPdf}
      limit={category?.progress?.requiredCount || 0}
      progress={category?.progress?.answeredCount || 0}
      sections={sections}
      name={careRecipientFirstName}
      toExit='/care-profile'
      onNext={handleNext}
      toPrevious={toLastFormPage}
      isNextDisabled={!category?.progress?.isComplete}
    >
      <SuccessContent
        header='Financial Status'
        cardHeader='Your next steps'
        isComplete={category?.progress?.isComplete || false}
        fixtures={[
          {
            icon: 'check-circle',
            text: 'Review your Financial Status answers to ensure all the needs captured are correct',
            iconColor: 'success' as 'success',
          },
          {
            icon: 'user',
            text: 'Once submitted you can chat to your Care Expert regarding your Financial Status',
            iconColor: 'blue' as 'blue',
          },
          {
            icon: 'file-contract',
            text: 'Your Care Expert will now provide you with your Funding Guide',
            iconColor: 'primary' as 'primary',
          },
        ]}
      />
    </CareProfileRoot>
  );
};

FinancialStatusComplete.displayName = 'FinancialStatusComplete';
export default FinancialStatusComplete;
