import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
/**
 * SimpleTable displays a list of question-answer pairs in a horizontal table layout.
 */
export const SimpleTable = ({ rows, actions }) => {
    return (_jsxs(_Fragment, { children: [' ', _jsx(TableContainer, { children: _jsx(Table, { children: _jsx("tbody", { children: rows.map((row, index) => (_jsxs("tr", { children: [_jsx(LabelCell, { children: row.label }), _jsx(ValueCell, { children: row.value })] }, index))) }) }) }), _jsx(ButtonContainer, { children: actions === null || actions === void 0 ? void 0 : actions.map((action, index) => (_jsx(Button, Object.assign({ ariaLabel: action.label, variant: action.buttonVariant || 'primary', onPress: () => action.onPress(rows), iconLeft: action.iconLeft, size: action.buttonSize || 'md', isDisabled: action.isDisabled }, { children: action.label }), index))) })] }));
};
const TableContainer = styled.div `
  width: 100%;
  overflow-y: auto;
  max-height: 600px;
`;
const Table = styled.table `
  width: 100%;
  border-collapse: collapse;
  margin-top: ${(props) => props.theme.spacing[16]};
  table-layout: fixed;
  th {
    text-align: left;
    padding: ${(props) => props.theme.spacing[12]};
    font: ${(props) => props.theme.typography.body.md.medium};
    color: ${(props) => props.theme.color.neutral[90]};
    border-bottom: 1px solid ${(props) => props.theme.color.neutral[30]};
    width: 50%;
  }
`;
const LabelCell = styled.td `
  padding: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[90]};
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[30]};
  vertical-align: middle;
  width: 50%;
  word-break: break-word;
  font-weight: bold;
`;
const ValueCell = styled.td `
  padding: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[70]};
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[30]};
  vertical-align: middle;
  white-space: pre-wrap;
  width: 50%;
  word-break: break-word;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[16]};
`;
SimpleTable.displayName = 'SimpleTable';
export default SimpleTable;
