import api from '.';
import { CareStage } from '@karehero/models';

export const personalizationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCareStage: builder.query<CareStage[], void>({
      query: () => '/care-stage',
      providesTags: ['CareStage'],
    }),
  }),
});

export const { useGetAllCareStageQuery } = personalizationApi;

export default personalizationApi;
