import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, useSearchParams } from 'react-router-dom';
// radix
import * as RadixTabs from '@radix-ui/react-tabs';
/**
 * Tabs is a component that allows users to navigate between different pages.
 */
export const Tabs = ({ tabs, defaultValue, value, onChange = (string) => { }, isNoPadding = false, }) => {
    // state
    const [val, setVal] = React.useState(undefined);
    // hooks
    const [searchParams, setSearchParams] = useSearchParams();
    const { search } = useLocation();
    // memos
    const computedDefaultValue = useMemo(() => {
        const tab = searchParams.get('tab');
        return tab || defaultValue;
    }, [defaultValue, val]);
    // effects
    useEffect(() => {
        const tab = searchParams.get('tab');
        setVal(tab || defaultValue || tabs[0].id);
        onChange(tab || defaultValue || tabs[0].id);
    }, [search]);
    return (_jsxs(TabsRoot, Object.assign({ defaultValue: val || computedDefaultValue || tabs[0].id }, (val && { value: val }), (onChange && { onValueChange: onChange }), { onValueChange: (val) => {
            searchParams.set('tab', val);
            setSearchParams(searchParams);
        } }, { children: [_jsx(TabsListWrapper, { children: _jsx(TabsList, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.id }, { children: tab.label }), tab.label))) }) }), tabs.map((tab) => (_jsx(TabsContent, Object.assign({ value: tab.id, "$isNoPadding": isNoPadding }, { children: tab.content }), tab.label)))] })));
};
const TabsRoot = styled(RadixTabs.Root) `
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
`;
const TabsListWrapper = styled.div `
  overflow-x: auto;
  overflow-y: hidden;

  padding: 0;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 99999px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 99999px;
    background: ${(props) => props.theme.color.neutral[60]};
    &:hover {
      background: ${(props) => props.theme.color.neutral[70]};
    }
  }
`;
const TabsList = styled(RadixTabs.List) `
  display: flex;
  position: relative;
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[40]};
  gap: ${(props) => props.theme.spacing[32]};
  padding: 0 ${(props) => props.theme.spacing[16]};
`;
const TabsTrigger = styled(RadixTabs.Trigger) `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[12]} 0;
  position: relative;
  bottom: -1px;
  cursor: pointer;

  white-space: nowrap;

  font: ${(props) => props.theme.typography.body.sm.regular};
  color: ${(props) => props.theme.color.neutral[80]};
  border-bottom: 2px solid transparent;
  user-select: none;

  &:hover {
    color: ${(props) => props.theme.color.neutral[100]};
  }

  &[data-state='active'] {
    font: ${(props) => props.theme.typography.body.sm.medium};
    color: ${(props) => props.theme.color.neutral[100]};
    border-bottom: 2px solid ${(props) => props.theme.color.primary[40]};
  }
`;
const TabsContent = styled(RadixTabs.Content) `
  flex: 1;
  ${(props) => !props.$isNoPadding &&
    css `
      padding: ${props.theme.spacing[36]} 0;
    `}
  outline: none;
  overflow: hidden;
`;
Tabs.displayName = 'Tabs';
export default Tabs;
