// react
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

// store
import { useSelector } from 'store';
import { useGetCareCircleQuery } from 'store/api/careCircle';
import {
  useCreateManyAnswerMutation,
  useGetAllCategoryByProfileQuery,
  useSubmitCareProfileFormMutation,
} from 'store/api/careProfile';
import { getCareCircleId } from 'store/slices/careCircleSlice';

// mixpanel
import {
  MixpanelForm,
  MixpanelFormEventType,
  useMixpanel,
  usePermissions,
} from 'hooks';

// models
import {
  categoryByID,
  buildForms,
  answersFromMap,
  buildSections,
  subQuestionsFromCategory,
  answersFromQuestions,
  listAllSubQuestionsOfForm,
  formFromCategory,
  CareProfileLabeledAnswer,
} from '@karehero/models';
import { careProfileDocDefinition } from 'pdf';

interface UseCareProfileOptions {
  categoryID: string;
  formID?: string;
  isSkipAnswers?: boolean;
}

export const useCareProfile = (options?: UseCareProfileOptions) => {
  // options
  const { categoryID } = options || {};

  // hooks
  const careCircleId = useSelector(getCareCircleId);
  const { data: careCircle } = useGetCareCircleQuery(careCircleId, {
    skip: !careCircleId,
  });
  const { trackForm } = useMixpanel();
  const navigate = useNavigate();

  const careProfileID = careCircle?.careProfile?.id || '';

  const { data: careProfileCategories } =
    useGetAllCategoryByProfileQuery(careProfileID);

  const [submitCareProfileForm] = useSubmitCareProfileFormMutation();

  const [createManyAnswer] = useCreateManyAnswerMutation();

  const { permissionByFormCategoryID } = usePermissions();

  // state
  const [answers, setAnswers] = useState<{
    [id: string]: string;
  }>({});

  // memos
  const category = useMemo(() => {
    if (!careProfileCategories || categoryID === '') return;

    return categoryByID(careProfileCategories, categoryID);
  }, [careProfileCategories, categoryID]);

  const sections = useMemo(
    () => (category && buildSections(category)) || [],
    [category],
  );

  const questions = useMemo(
    () => category && subQuestionsFromCategory(category),
    [category],
  );

  const savedAnswers = useMemo(
    () => questions && answersFromQuestions(questions),
    [questions],
  );

  const forms = useMemo(
    () => category && answers && buildForms(category, answers),
    [category, answers],
  );

  const careRecipientFirstName = useMemo(
    () => careCircle?.careRecipientAccount?.firstName,
    [careCircle],
  );

  const careRecipientName = useMemo(
    () =>
      `${careCircle?.careRecipientAccount?.firstName || ''} ${
        careCircle?.careRecipientAccount?.lastName || ''
      }`,
    [careCircle],
  );

  const title = useMemo(
    () => `${careRecipientFirstName || 'Your Loved One'}'s ${category?.title}`,
    [category, careRecipientFirstName],
  );

  const toFirstFormPage = useMemo(
    () => `/${category?.id}/${category?.firstFormID}`,
    [category],
  );

  const toLastFormPage = useMemo(
    () => `/${category?.id}/${category?.finalFormID}`,
    [category],
  );

  const permission = useMemo(
    () => permissionByFormCategoryID(categoryID || ''),
    [categoryID, permissionByFormCategoryID],
  );

  const formType = useMemo(() => {
    return (
      (category &&
        {
          'attendance-allowance': MixpanelForm.AttendanceAllowance,
          'care-assessment': MixpanelForm.CareAssessment,
          'financial-status': MixpanelForm.FinancialStatus,
        }[category.id]) ||
      MixpanelForm.CareAssessment
    );
  }, [category]);

  // callbacks
  const createManyAnswerWithProfileID = useCallback(
    (a: { [id: string]: string }) =>
      a &&
      createManyAnswer({
        careProfileId: careProfileID,
        answers: answersFromMap(careProfileID, a),
      }),
    [createManyAnswer, careProfileID],
  );

  const handlePrintPdf = useCallback(
    (
      profileAnswers?: CareProfileLabeledAnswer[],
      recipientName?: string,
      isSummary: boolean = false,
    ) => {
      if (!category) return;

      const answersToUse =
        profileAnswers && profileAnswers.length > 0 ? profileAnswers : answers;

      careProfileDocDefinition(
        [category],
        answersToUse,
        recipientName || careRecipientName,
        category.id,
        category.title,
        isSummary,
      ).open();
    },
    [category, careRecipientName, answers],
  );

  const trackCompleted = useCallback(() => {
    trackForm(formType, MixpanelFormEventType.Completed, {});
  }, [trackForm, formType]);

  const trackStarted = useCallback(() => {
    trackForm(formType, MixpanelFormEventType.Started, {});
  }, [trackForm, formType]);

  const trackSubmitted = useCallback(() => {
    trackForm(formType, MixpanelFormEventType.Submitted, {});
  }, [trackForm, formType]);

  const handleSubmit = useCallback(() => {
    trackSubmitted();
    careProfileID &&
      category &&
      submitCareProfileForm({
        careProfileId: careProfileID,
        careProfileCategoryId: category?.id,
        eventType: 'form-submitted',
      });
  }, [trackSubmitted, submitCareProfileForm, careProfileID, category]);

  const handleStart = useCallback(() => {
    if (!careProfileID || !category || !toFirstFormPage) return;

    trackStarted();
    submitCareProfileForm({
      careProfileId: careProfileID,
      careProfileCategoryId: category?.id,
      eventType: 'form-started',
    });
    navigate(toFirstFormPage);
  }, [
    trackStarted,
    navigate,
    submitCareProfileForm,
    careProfileID,
    category,
    toFirstFormPage,
  ]);

  // effects
  useEffect(() => {
    savedAnswers && setAnswers?.(savedAnswers);
  }, [savedAnswers]);

  return {
    careProfileID,
    title,
    formType,
    answers,
    category,
    forms,
    sections,
    careCircle,
    careRecipientFirstName,
    careRecipientName,
    toFirstFormPage,
    toLastFormPage,
    permission,
    setAnswers,
    createManyAnswer: createManyAnswerWithProfileID,
    trackCompleted,
    trackSubmitted,
    handleStart,
    handleSubmit,
    handlePrintPdf,
  };
};

export const useCareProfileForm = (options?: UseCareProfileOptions) => {
  const resp = useCareProfile(options);

  const {
    forms,
    category,
    formType,
    createManyAnswer,
    answers: allAnswers,
    setAnswers: setAllAnswers,
  } = resp;

  const { careProfileFormId: careProfileFormIdParams } = useParams();
  const navigate = useNavigate();
  const { trackForm } = useMixpanel();

  const careProfileFormId = useMemo(
    () => options?.formID || careProfileFormIdParams,
    [careProfileFormIdParams, options],
  );

  const profileForm = useMemo(
    () => category && formFromCategory(category, careProfileFormId || ''),
    [category, careProfileFormId],
  );

  const formQuestions = useMemo(
    () => profileForm && listAllSubQuestionsOfForm(profileForm),
    [profileForm],
  );

  const form = useMemo(
    () => forms?.find((form) => form.id === careProfileFormId),
    [forms, careProfileFormId],
  );

  const answers = useMemo(() => {
    let a: { [k: string]: string } = {};
    formQuestions?.forEach((q) => {
      const ans = allAnswers[q.id];
      if (ans !== undefined) {
        a[q.id] = ans;
      }
    });
    return a;
  }, [formQuestions, allAnswers]);

  const toPrevious = useMemo(() => {
    return form?.prevFormID
      ? `/${category?.id}/${form?.prevFormID}`
      : `/${category?.id}`;
  }, [category, form]);

  const toNext = useMemo(() => {
    return `/${category?.id}/${form?.nextFormID || 'complete'}`;
  }, [category, form]);

  // mixpanel events
  const trackViewed = useCallback(() => {
    trackForm(formType, MixpanelFormEventType.Viewed, {
      carePlanFormId: careProfileFormId,
    });
  }, [trackForm, formType, careProfileFormId]);

  const trackSaved = useCallback(() => {
    trackForm(formType, MixpanelFormEventType.Saved, {
      carePlanFormId: careProfileFormId,
    });
  }, [trackForm, formType, careProfileFormId]);

  // callbacks
  const setAnswers = useCallback(
    (newAnswers: { [key: string]: string }) => {
      setAllAnswers({ ...allAnswers, ...newAnswers });
    },
    [allAnswers, setAllAnswers],
  );

  const handleSave = useCallback(() => {
    createManyAnswer(answers);
    trackSaved();
    navigate(toNext);
  }, [createManyAnswer, navigate, answers, toNext, trackSaved]);

  return {
    ...resp,
    form,
    answers,
    careProfileFormId,
    toPrevious,
    toNext,
    setAnswers,
    trackViewed,
    trackSaved,
    handleSave,
  };
};
