// react
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { Loading as LoadingPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';
import { Account } from '@karehero/models';

// hooks
import { MixpanelEvent, useApp, useMixpanel } from 'hooks';

// launch darkly
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { createLdContext } from 'launchDarkly';

// kinde
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

/**
 * Authenticate is a component that renders the authentication page.
 */
const Authenticate = () => {
  // hooks
  const account = useSelector(getAccount);
  const navigate = useNavigate();
  const ldClient = useLDClient();
  const flags = useFlags();
  const { track } = useMixpanel();
  const { isApp, isAndroid, isApple } = useApp();
  const { isAuthenticated: isAuthenticatedKinde, isLoading: isLoadingKinde } =
    useKindeAuth();

  // methods
  const handleAnalyticsIdentify = useCallback(
    (account: Account) => {
      const newContext = createLdContext(account);
      ldClient?.identify(newContext);
    },
    [ldClient],
  );

  // effects
  useEffect(() => {
    if (!isLoadingKinde && !isAuthenticatedKinde) {
      const message = encodeURIComponent('Please sign in to continue.');
      navigate(`/sign-in?message=${message}`);
    }
  }, [isLoadingKinde, isAuthenticatedKinde, navigate]);

  useEffect(() => {
    if (!account) return;
    if (isApp && isApple) {
      track(MixpanelEvent.AuthenticatedIOS, {});
    }
    if (isApp && isAndroid) {
      track(MixpanelEvent.AuthenticatedAndroid, {});
    }

    handleAnalyticsIdentify(account);

    switch (account.accountRole.name) {
      case 'KareHero Administrator':
        return navigate('/admin');
      case 'Organization Owner':
        return navigate('/');
      case 'Member':
        return navigate('/');
      default:
        return navigate('/');
    }
  }, [
    account,
    navigate,
    handleAnalyticsIdentify,
    isApp,
    isApple,
    isAndroid,
    track,
  ]);

  return (
    <LoadingPage
      isEasterEgg={
        false && process.env.NODE_ENV === 'development' && flags.easterEgg
      }
    />
  );
};

export default Authenticate;
