import { jsx as _jsx } from "react/jsx-runtime";
// components
import { Tabs } from "../../atoms";
import { Chat, HubSpotChat } from "../../organisms";
/**
 * Messenger shows all chats.
 */
export const Messenger = ({ kindeEmail, messages, onSend, onReset, isTyping, isKareheroAI, hubspotIdentificationToken, account, }) => {
    return !isKareheroAI ? (_jsx(HubSpotChat, { kindeEmail: kindeEmail, token: hubspotIdentificationToken })) : (_jsx(Tabs, { isNoPadding: true, tabs: [
            {
                id: 'karehero-ai',
                label: 'KareHero AI',
                content: (_jsx(Chat, { messages: messages, onSend: onSend, onReset: onReset, isTyping: isTyping, account: account })),
            },
            {
                id: 'care-expert',
                label: 'Care expert',
                content: (_jsx(HubSpotChat, { kindeEmail: kindeEmail, token: hubspotIdentificationToken })),
            },
        ] }));
};
Messenger.displayName = 'Messenger';
export default Messenger;
