import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { FeesDescription, GdprConsent, SjpClientForm, } from "..";
import { ReferralProcess } from "../../molecules";
/**
 * InviteClientForm renders the account settings page.
 */
export const InviteClientForm = ({ value, onChange, setIsValid, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    // states
    const [client, setClient] = useState({
        firstName: (_a = value.referral) === null || _a === void 0 ? void 0 : _a.firstName,
        lastName: (_b = value.referral) === null || _b === void 0 ? void 0 : _b.lastName,
        email: (_c = value.referral) === null || _c === void 0 ? void 0 : _c.email,
        phoneNumber: (_d = value.referral) === null || _d === void 0 ? void 0 : _d.phone,
        nameOfPersonReceivingCare: (_e = value.referral) === null || _e === void 0 ? void 0 : _e.personReceivingCare,
        contextAboutSituation: (_f = value.referral) === null || _f === void 0 ? void 0 : _f.careSituationContext,
    });
    const [fees, setFees] = useState({
        discountCode: (_g = value.referral) === null || _g === void 0 ? void 0 : _g.discountCode,
        feeSelection: (_h = value.referral) === null || _h === void 0 ? void 0 : _h.feePaid,
    });
    const [gdpr, setGdpr] = useState({
        gdprSelections: (_j = value.referral) === null || _j === void 0 ? void 0 : _j.gdprSelections,
    });
    // effects
    useEffect(() => {
        onChange({
            referrer: {},
            referral: {
                firstName: client.firstName,
                lastName: client.lastName,
                email: client.email,
                phone: client.phoneNumber,
                discountCode: fees.discountCode,
                feePaid: fees.feeSelection,
                personReceivingCare: client.nameOfPersonReceivingCare,
                careSituationContext: client.contextAboutSituation,
                gdprSelections: gdpr.gdprSelections,
            },
        });
    }, [client, fees, gdpr]);
    return (_jsx(Wrapper, { children: _jsxs(Page, { children: [_jsx(ReferralProcess, {}), _jsx(SjpClientForm, { value: client, setValue: setClient, noText: true, setIsValid: setIsValid }), _jsx(FeesDescription, { value: fees, setValue: setFees, isNoTitle: true }), _jsx(GdprConsent, { value: gdpr, setValue: setGdpr })] }) }));
};
// styles
const Page = styled.div `
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const Wrapper = styled.div `
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${(props) => props.theme.breakpoint.sm(css `
      min-width: 560px;
    `)}
  ${(props) => props.theme.breakpoint.md(css `
      min-width: 920px;
    `)}
  gap: ${(props) => props.theme.spacing[36]};
  padding: ${(props) => props.theme.spacing[20]};
`;
InviteClientForm.displayName = 'InviteClientForm';
export default InviteClientForm;
