import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
/**
 * CarouselIndicator shows dots where only one is highlighted.
 */
export const CarouselIndicator = ({ total, index, color = 'dark', }) => {
    const dots = useMemo(() => {
        let tmpArray = [];
        for (let i = 0; i < total; i++) {
            tmpArray.push(i);
        }
        return tmpArray;
    }, [total]);
    return (_jsx(Wrapper, { children: dots.map((dot) => (_jsx(IndicatorDot, { "$isHighlighted": dot === index, "$color": color }, dot))) }));
};
const Wrapper = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[12]};
`;
const IndicatorDot = styled.div `
  min-width: 14px;
  width: 14px;
  max-width: 14px;
  min-height: 14px;
  height: 14px;
  max-height: 14px;
  border-radius: 99999px;
  transition-duration: 0.2s;
  background: ${(props) => {
    if (props.$isHighlighted) {
        return props.theme.color.primary[80];
    }
    else {
        switch (props.$color) {
            case 'light':
                return props.theme.color.neutral[10];
            case 'dark':
            default:
                return props.theme.color.neutral[60];
        }
    }
}};
`;
CarouselIndicator.displayName = 'CarouselIndicator';
export default CarouselIndicator;
