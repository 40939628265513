import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
// models
import { FieldType } from '@karehero/models';
// components
import { Card, Title } from "../../atoms";
import { createFieldHelper } from "../EditorFields/fieldHelper";
import { EditorFields } from "../EditorFields/EditorFields";
// hooks
import { useWindowSize } from "../../../hooks";
// theme
import { breakpointValues } from "../../../theme/breakpoint";
const valueFields = createFieldHelper({
    feeSelection: {
        label: '',
        ariaLabel: 'fee-selection',
        placeholder: 'Select',
        field: {
            type: FieldType.RadioGroup,
            variant: 'default',
            maxColumns: 1,
            options: [
                {
                    label: 'I, the referring Partner, agree to pay the fee for the Concierge Service',
                    value: 'partner-paying-fee',
                },
                {
                    label: 'My Client will be paying the fee for this service and is aware of the cost',
                    value: 'client-paying-fee',
                },
                {
                    label: 'Time Client',
                    value: 'time-client',
                },
            ],
        },
    },
});
const discountFields = createFieldHelper({
    discountCode: {
        label: 'If you have a discount code, please enter it here.',
        placeholder: 'discount-code',
        field: {
            type: FieldType.Text,
        },
    },
});
const Wrap = ({ children }) => {
    const [width] = useWindowSize();
    if (width >= breakpointValues.mdValue) {
        return _jsx(_Fragment, { children: children });
    }
    else {
        return _jsx(InnerFeeContainer, { children: children });
    }
};
/**
 * FeesDescription renders the fee description page.
 */
export const FeesDescription = ({ value, setValue, isNoTitle = false, }) => {
    return (_jsxs(Wrapper, { children: [!isNoTitle && _jsx(Title, Object.assign({ isNoMargin: true }, { children: "KareHero Fees" })), _jsxs(FeeContainer, { children: [_jsxs(Wrap, { children: [_jsx(Card, Object.assign({ color: 'lime', isBordered: false, isPaddingSm: true }, { children: _jsxs(FeePanel, { children: [_jsx(SubTitle, { children: "KareHero Care Concierge Fees as follows:" }), _jsxs(StyledNumberedList, { children: [_jsx("li", { children: "\u00A3500 + VAT for full service" }), _jsx("li", { children: "\u00A3250 + VAT for fund-only service" }), _jsx("li", { children: "Free for Time advance clients" })] })] }) }), 'fee-description'), _jsx(Card, Object.assign({ color: 'lime', isBordered: false, isPaddingSm: true }, { children: _jsxs(FeePanel, { children: [_jsxs(SubTitle, { children: ["Who will pay for the service?", _jsx(StyledIsRequired, { children: '*' })] }), _jsx(EditorFields, { fields: valueFields, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), { feeSelection: f.feeSelection })) })] }) }), 'fee-payer')] }), _jsx(Card, Object.assign({ color: 'lime', isBordered: false, isPaddingSm: true }, { children: _jsxs(FeePanel, { children: [_jsx(SubTitle, { children: "Discount Code" }), _jsx(EditorWrapper, { children: _jsx(EditorFields, { fields: discountFields, value: value, onChange: (f) => setValue(Object.assign(Object.assign({}, value), { discountCode: f.discountCode })) }) })] }) }), 'fee-discount')] })] }));
};
// styles
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const EditorWrapper = styled.div `
  max-width: 400px;
  min-width: 200px;
  margin-right: ${(props) => props.theme.spacing[16]};
`;
const FeeContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[24]};
  ${(props) => props.theme.breakpoint.md(css `
      flex-direction: row;
      align-items: stretch;
    `)}
`;
const InnerFeeContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[24]};
  ${(props) => props.theme.breakpoint.sm(css `
      flex-direction: row;
      align-items: stretch;
    `)}
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['2xl']};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: left;
`;
const FeePanel = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 200px;
  gap: ${(props) => props.theme.spacing[8]};
`;
const StyledNumberedList = styled.ol `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  margin: 0;
  padding: ${(props) => props.theme.spacing[16]};
  padding-top: 0;
  padding-bottom: 0;
`;
const StyledIsRequired = styled.span `
  margin-left: ${(props) => props.theme.spacing[2]};
  color: ${(props) => props.theme.color.danger[60]};
`;
FeesDescription.displayName = 'FeesDescription';
export default FeesDescription;
