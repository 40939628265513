import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Container } from "../../atoms";
import { TOS } from "../../molecules";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
export const OnboardingTOS = toGenericOnboarding(({ setIsNextDisabled, config, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    // memos
    const isComplete = useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        const fields = (_c = Object.values(((_b = (_a = config === null || config === void 0 ? void 0 : config[0]) === null || _a === void 0 ? void 0 : _a.consentForm) === null || _b === void 0 ? void 0 : _b.fields) || {})) === null || _c === void 0 ? void 0 : _c[0];
        const val = (_e = (_d = config === null || config === void 0 ? void 0 : config[0]) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e[fields === null || fields === void 0 ? void 0 : fields.id];
        if (val === undefined || val === null || val === '') {
            return false;
        }
        const options = ((_f = fields === null || fields === void 0 ? void 0 : fields.field) === null || _f === void 0 ? void 0 : _f.options) || [];
        return JSON.parse(val).length >= options.length;
    }, [(_a = config === null || config === void 0 ? void 0 : config[0]) === null || _a === void 0 ? void 0 : _a.value]);
    // effects
    useEffect(() => {
        setIsNextDisabled(!isComplete);
    }, [isComplete]);
    return (_jsx(Container, Object.assign({ size: 'md' }, { children: _jsx(Wrapper, { children: ((_c = (_b = config === null || config === void 0 ? void 0 : config[0]) === null || _b === void 0 ? void 0 : _b.consentForm) === null || _c === void 0 ? void 0 : _c.fields) && (_jsx(TOS, { consent: (_d = config === null || config === void 0 ? void 0 : config[0]) === null || _d === void 0 ? void 0 : _d.value, setConsent: (_e = config === null || config === void 0 ? void 0 : config[0]) === null || _e === void 0 ? void 0 : _e.setValue, consentFields: (_g = (_f = config === null || config === void 0 ? void 0 : config[0]) === null || _f === void 0 ? void 0 : _f.consentForm) === null || _g === void 0 ? void 0 : _g.fields })) }) })));
});
const Wrapper = styled.div `
  height: 100%;
  padding: ${(props) => props.theme.spacing[32]} 0;
`;
OnboardingTOS.displayName = 'OnboardingTOS';
export default toGenericOnboarding(OnboardingTOS);
