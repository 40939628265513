import api from '.';
import {
  CareProfileCategory,
  CareProfileAnswerRequest,
  CareProfileFormEvent,
  CareProfileLabeledAnswer,
} from '@karehero/models';

export const careProfileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategoryByProfile: builder.query<CareProfileCategory[], string>({
      query: (id) => `/care-profile/${id}/category`,
      providesTags: ['CareProfileCategories'],
      keepUnusedDataFor: 0,
    }),
    createManyAnswer: builder.mutation<void, CareProfileAnswerRequest>({
      query: (body: CareProfileAnswerRequest) => ({
        url: `care-profile/${body.careProfileId}/answer`,
        method: 'POST',
        body: body.answers,
      }),
      invalidatesTags: ['CareProfileCategories', 'CareCircles'],
    }),
    submitCareProfileForm: builder.mutation<void, CareProfileFormEvent>({
      query: (careProfileFormEvent: CareProfileFormEvent) => ({
        url: `/care-profile/${careProfileFormEvent.careProfileId}/category/${careProfileFormEvent.careProfileCategoryId}/submit`,
        method: 'POST',
        body: {
          eventType: careProfileFormEvent.eventType,
        },
      }),
    }),
    getLabeledAnswersByCategory: builder.query<
      CareProfileLabeledAnswer[],
      { careProfileId: string; categoryId: string }
    >({
      query: ({ careProfileId, categoryId }) => ({
        url: `/care-profile/${careProfileId}/category/${categoryId}/labeled-answers`,
        method: 'GET',
      }),
      providesTags: ['CareProfileCategories', 'CareCircles'],
    }),
  }),
});

export const {
  useGetAllCategoryByProfileQuery,
  useCreateManyAnswerMutation,
  useSubmitCareProfileFormMutation,
  useGetLabeledAnswersByCategoryQuery,
} = careProfileApi;

export default careProfileApi;
