// react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'store';

// service workers
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';

// styles
import 'index.css';

// components
import App from 'App';

// launchdarkly
import { getLdProvider } from 'launchDarkly';

// hotjar
import Hotjar from '@hotjar/browser';

// sentry
import * as Sentry from '@sentry/react';

// kinde
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import { token } from 'store/slices/token';

// hotjar
const siteId = process.env.NODE_ENV === 'production' ? 3612117 : 3535435;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const env = process.env.REACT_APP_BUILD_ENVIRONMENT || 'production';

// sentry
const apiURL =
  {
    dev: 'https://api.dev.karehero.com',
    staging: 'https://api.staging.karehero.com',
    production: 'https://api.karehero.com',
  }[env] || 'https://api.karehero.com';

const kindeURL =
  {
    dev: 'https://karehero-staging.uk.kinde.com',
    staging: 'https://karehero-staging.uk.kinde.com',
    production: 'https://karehero.kinde.com',
  }[env] || 'https://karehero.kinde.com';

if (process.env.REACT_APP_BUILD_ENVIRONMENT) {
  Sentry.init({
    dsn: 'https://d7d0f2dc4c5cc660e48177e0c6ef2504@o4508064851755008.ingest.de.sentry.io/4508064856146000',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [apiURL, kindeURL],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_BUILD_ENVIRONMENT,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

(async () => {
  const LDProvider = await getLdProvider();

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <LDProvider>
          <KindeProvider
            audience={process.env.REACT_APP_KINDE_API_AUDIENCE}
            clientId={process.env.REACT_APP_KINDE_TOKEN_CLIENT_ID}
            domain={process.env.REACT_APP_KINDE_TOKEN_DOMAIN || ''}
            logoutUri={`${window.location.origin}/sign-in`}
            redirectUri={`${window.location.origin}/authenticate`}
            onRedirectCallback={(user, app_state) => {
              token.signIn()(app_state?.token as string | undefined);
            }}
          >
            <App />
          </KindeProvider>
        </LDProvider>
      </Provider>
    </React.StrictMode>,
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
