var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Icon, Input } from "../../../atoms";
import { Message, TypingDots } from "../..";
export const Chat = ({ className, messages, onSend, onReset, isTyping, account, }) => {
    // state
    const [message, setMessage] = useState('');
    // refs
    const ref = useRef(null);
    // methods
    const handleSendMessage = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!message || isTyping)
            return;
        setMessage('');
        try {
            yield (onSend === null || onSend === void 0 ? void 0 : onSend(message));
        }
        catch (error) {
            console.error(error);
        }
    }), [message, onSend, isTyping]);
    // effects
    useEffect(() => {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({});
    }, [messages, isTyping]);
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsxs(Body, { children: [_jsx(RestartWrapper, Object.assign({ "$isHidden": ((messages === null || messages === void 0 ? void 0 : messages.length) || 1) <= 1 }, { children: _jsx(Button, { ariaLabel: 'new conversation', onPress: onReset, size: 'md', iconLeft: 'rotate-right', iconType: 'fas', variant: 'link' }) })), messages === null || messages === void 0 ? void 0 : messages.map((message) => (_jsx(Message, { isSender: message.role === 'user', value: message.content, account: account, links: message.links }, message.id))), isTyping && (_jsx(Message, { children: _jsx(TypingDots, {}) })), _jsx(AlwaysScrollToBottom, { ref: ref })] }), _jsx(Footer, { children: _jsxs(MessageBox, Object.assign({ onSubmit: (e) => {
                        e.preventDefault();
                        handleSendMessage();
                    } }, { children: [_jsx(StyledInput, { placeholder: 'Help me with...', value: message, onChange: (val) => setMessage(val), autoFocus: true }), _jsx(SendButton, Object.assign({ type: 'submit', disabled: !message || isTyping }, { children: _jsx(Icon, { icon: 'paper-plane-top', fontSize: 24, type: 'fas' }) }))] })) })] })));
};
const StyledInput = styled(Input) `
  border: none !important;
  outline: none !important;
  & input {
    border: none !important;
    outline: none !important;
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
const Body = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[16]};
  background: ${(props) => props.theme.color.neutral[10]};
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`;
const Footer = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[16]};
  padding-bottom: ${(props) => props.theme.spacing[12]};
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
`;
const MessageBox = styled.form `
  display: flex;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
`;
const SendButton = styled.button `
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.primary[30]};

  transition-duration: 200ms;
  &:hover {
    color: ${(props) => props.theme.color.primary[80]};
  }

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.color.neutral[50]};
  }
`;
const Disclaimer = styled.div `
  font: ${(props) => props.theme.typography.body.xxs.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  padding: 0 ${(props) => props.theme.spacing[16]};
  text-align: center;
`;
const AlwaysScrollToBottom = styled.div `
  height: 0;
  max-height: 0;
`;
const ChatStart = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const ChatStartHelper = styled.div `
  text-align: center;
  font: ${(props) => props.theme.typography.body.xxs.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const RestartWrapper = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.$isHidden ? 0 : 1)};
  transition-duration: 400ms;
  padding: ${(props) => props.theme.spacing[12]};
`;
Chat.displayName = 'Chat';
export default Chat;
