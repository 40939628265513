import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
/**
 * Container is a container for content which is centered and has a max width.
 */
export const Container = ({ className, children, size = 'xl', isScroll = false, isInline = false, isNoPadding = false, isFlex = false, }) => {
    return (_jsx(StyledContainer, Object.assign({ className: className, "$isInline": isInline, "$isScroll": isScroll, "$isNoPadding": isNoPadding, "$isFlex": isFlex }, { children: _jsx(Content, Object.assign({ size: size }, { children: children })) })));
};
const StyledContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  ${(props) => !props.$isInline && 'height: 100%; flex: 1;'}
  ${(props) => props.$isFlex && 'flex: 1;'}

  ${(props) => props.$isScroll &&
    css `
      overflow-y: auto;
    `}

  padding-left: ${(props) => props.theme.spacing[12]};
  padding-right: ${(props) => props.theme.spacing[12]};

  ${(props) => props.theme.breakpoint.sm(css `
      padding-left: ${props.theme.spacing[32]};
      padding-right: ${props.theme.spacing[32]};

      ${props.$isNoPadding &&
    css `
        padding: 0;
      `}
    `)};

  ${(props) => props.$isNoPadding &&
    css `
      padding: 0;
    `}

  transition: padding 0ms ease-in-out 125ms;
`;
const Content = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          max-width: 520px;
        `;
        case 'md':
            return css `
          max-width: 720px;
        `;
        case 'lg':
            return css `
          max-width: 960px;
        `;
        case 'xl':
            return css `
          max-width: 1200px;
        `;
        case 'full':
            return css `
          max-width: 100%;
        `;
    }
}}

  transition: max-width 0ms ease-in-out 125ms;
`;
Container.displayName = 'Container';
export default Container;
