import api from '.';
import { InvitedMember } from '@karehero/models';

export const invitedMemberApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvitedMember: builder.query<InvitedMember[], void>({
      query: () => '/invite',
      providesTags: ['InvitedMembers'],
    }),
    updateInviteAwaitingFee: builder.mutation<void, InvitedMember>({
      query: (invitedMember) => ({
        url: `/invite/${invitedMember.id}/mark-fee-paid`,
        method: 'POST',
      }),
      invalidatesTags: ['InvitedMembers'],
    }),
  }),
});

export const {
  useGetAllInvitedMemberQuery,
  useUpdateInviteAwaitingFeeMutation,
} = invitedMemberApi;

export default invitedMemberApi;
