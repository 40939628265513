// react
import { Navigate } from 'react-router-dom';

import { useCareProfile } from 'hooks';

interface ProtectedFormStartProps {
  categoryId: string;
  Component: React.ComponentType;
}

// Protected route component, reusable for any form start page that needs this protection or redirect
const ProtectedFormStart = ({
  categoryId,
  Component,
}: ProtectedFormStartProps) => {
  const { toFirstFormPage, category } = useCareProfile({
    categoryID: categoryId,
  });

  if (!category) return null;

  // If the form has already been started we skip the first page. This status is added in another PR
  return category?.careProfileFormEvents?.find(
    (ev) => ev.eventType === 'form-started',
  ) ? (
    <Navigate to={toFirstFormPage} replace />
  ) : (
    <Component />
  );
};

ProtectedFormStart.displayName = 'ProtectedFormStart';
export default ProtectedFormStart;
