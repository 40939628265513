var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Avatar, Button, Divider, Icon } from "../../../atoms";
// libs
import Markdown from 'react-markdown';
// assets
// @transform-path ../../../../../../../src/assets/chat/jane.jpg
import janeSrc from "../../../../../../../src/assets/chat/jane.jpg";
/**
 * Message renders a message.
 */
export const Message = ({ className, children, value, isSender, account, links, }) => {
    // memos
    const accountInitials = useMemo(() => {
        let initials = '';
        if (account === null || account === void 0 ? void 0 : account.firstName)
            initials += account.firstName[0];
        if (account === null || account === void 0 ? void 0 : account.lastName)
            initials += account.lastName[0];
        return initials;
    }, [account]);
    return (_jsxs(Wrapper, Object.assign({ className: className, "$isSender": isSender }, { children: [!isSender && _jsx(Avatar, { size: 'xs', fallback: 'J', src: janeSrc }), _jsxs(MessageWrapper, { children: [_jsxs(Main, { children: [_jsx(Name, Object.assign({ "$isSender": isSender }, { children: isSender ? 'You' : 'Jane (AI)' })), _jsxs(Content, Object.assign({ "$isSender": isSender }, { children: [children, value &&
                                        (isSender ? (value) : (_jsx(Markdown, Object.assign({ components: {
                                                p: (_a) => {
                                                    var { node } = _a, props = __rest(_a, ["node"]);
                                                    return _jsx("p", Object.assign({}, props));
                                                },
                                                pre: (_a) => {
                                                    var { node } = _a, props = __rest(_a, ["node"]);
                                                    return _jsx(Code, Object.assign({}, props));
                                                },
                                                hr: (_a) => {
                                                    var { node } = _a, props = __rest(_a, ["node"]);
                                                    return _jsx(Divider, { isOr: false, color: 'dark', margin: 16 });
                                                },
                                                a: (_a) => {
                                                    var { node } = _a, props = __rest(_a, ["node"]);
                                                    return (
                                                    // @ts-ignore
                                                    _jsx(StyledLink, Object.assign({ to: props.href || '' }, props)));
                                                },
                                            } }, { children: value }))))] }))] }), links && links.length !== 0 && (_jsx(LinkGroup, { children: links.map((link) => (_jsxs(LinkCard, Object.assign({ to: link.url }, { children: [_jsxs(ImgWrapper, Object.assign({ "$color": link === null || link === void 0 ? void 0 : link.color }, { children: [link.imageURL && (_jsx("img", { src: link.imageURL + '?h=400', alt: link.title })), !link.imageURL && (_jsx(IconWrapper, { children: _jsx(Icon, { icon: link === null || link === void 0 ? void 0 : link.icon, fontSize: 48 }) }))] })), _jsx(LinkTitle, { children: link.title })] })))) }))] })] })));
};
const Wrapper = styled.div `
  display: flex;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[8]};
  ${(props) => props.$isSender
    ? css `
          justify-content: flex-end;
        `
    : css `
          justify-content: flex-start;
        `}
`;
const Name = styled.div `
  font: ${(props) => props.theme.typography.body.xs.medium};
  color: ${(props) => props.theme.color.neutral[80]};
  ${(props) => props.$isSender && 'text-align: right'};
`;
const Main = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[2]};
`;
const IconWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const MessageWrapper = styled.div `
  display: flex;
  flex-direction: column;

  max-width: 75%;
  gap: ${(props) => props.theme.spacing[8]};

  & p:first-child {
    margin-top: 0;
  }
  & p:last-child {
    margin-bottom: 0;
  }
`;
const LinkGroup = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[8]};
`;
const ImgWrapper = styled.div `
  width: 100%;
  height: 100px;
  min-width: 200px;
  background: ${(props) => props.$color
    ? props.theme.color.cards[props.$color].primary
    : props.theme.color.primary[10]};
  color: ${(props) => props.$color
    ? props.theme.color.cards[props.$color].tertiary
    : props.theme.color.primary[80]};

  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-out;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease-out;
  }
`;
const LinkCard = styled(Link) `
  all: unset;
  cursor: pointer;
  border-radius: 8px;
  background: #ffffff;
  color: #425b76;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover ${ImgWrapper}:after {
    opacity: 0.3;
  }

  &:hover ${ImgWrapper} > * {
    transform: scale(1.1);
  }
`;
const LinkTitle = styled.div `
  font: ${(props) => props.theme.typography.body.sm.regular};
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[16]};
  text-align: center;
  width: 100%;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Content = styled.div `
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[16]};
  background: ${(props) => props.$isSender ? props.theme.color.primary[30] : '#eaf0f6'};
  color: ${(props) => props.$isSender ? props.theme.color.neutral[10] : '#425b76'};
  border-radius: 8px;
  ${(props) => props.$isSender
    ? 'border-top-right-radius: 2px'
    : 'border-top-left-radius: 2px'};
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const Code = styled.pre `
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 8px;
  font-size: 0.9rem;
  font-family: 'Fira Code', monospace;
  overflow-x: auto;
`;
const StyledLink = styled(Link) `
  font: inherit;
  text-decoration: underline;
  color: ${(props) => props.theme.color.primary[80]};
`;
const StyledButton = styled(Button) `
  padding: 0;
`;
Message.displayName = 'Message';
export default Message;
