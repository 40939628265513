// react
import { useEffect } from 'react';

// components
import {
  CareSearch as CareSearchPage,
  Loader,
  LoadingTransition,
} from '@karehero/llama';

// views
import Directory from 'directory/Directory';

// store
import {
  useGetShortlistBySessionQuery,
  useGetShortlistDataBySessionQuery,
  useMarkShortlistCareHomeSecuredMutation,
} from 'store/api/shortlist';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

/**
 * CareSearch shows 
a search bar and a list of care homes based on the user's search criteria and shortlist.
 */
const CareSearch = () => {
  // hooks
  const { data: careHomeShortlistData, isLoading: isLoadingData } =
    useGetShortlistDataBySessionQuery();
  const { data: careHomeShortlist, isLoading: isLoadingShortlist } =
    useGetShortlistBySessionQuery();
  const [markCareHomeSecured] = useMarkShortlistCareHomeSecuredMutation();

  const { track } = useMixpanel();

  // effects
  useEffect(() => {
    if (careHomeShortlist && careHomeShortlistData) {
      track(MixpanelEvent.ShortlistViewed, {
        id: careHomeShortlistData.id,
      });
    }
  }, [careHomeShortlistData, careHomeShortlist, track]);

  return (
    <LoadingTransition
      isLoading={isLoadingData || isLoadingShortlist}
      loading={<Loader />}
      content={
        <>
          {careHomeShortlist && careHomeShortlistData && (
            <CareSearchPage
              shortlist={careHomeShortlist}
              shortlistData={careHomeShortlistData}
              onMarkSecured={markCareHomeSecured}
            />
          )}
          <Directory />
        </>
      }
    />
  );
};

export default CareSearch;
