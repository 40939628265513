let getToken: () => Promise<string | undefined>;
let signIn: (t: string | undefined) => void;

export const token = {
  getToken: () => getToken,
  setTokenGetter: (func: () => Promise<string | undefined>) =>
    (getToken = func),
  signIn: () => signIn,
  setSignIn: (func: (t: string | undefined) => void) => (signIn = func),
};
