// react
import { useCallback } from 'react';

import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

const env = process.env.REACT_APP_BUILD_ENVIRONMENT;
const ConnectionID = {
  GOOGLE: 'conn_018f08586998f0eac9cfe1e83083a85d',
  FACEBOOK: 'conn_018f085869984eededa5e9c89ea51977',
  APPLE: 'conn_018f0858699813cd2bcae3e27246eddf',
  EMAIL:
    env === 'production'
      ? 'conn_018f085869975f5b856eda3f9e4e3df6'
      : 'conn_018f085869531133e802e4ce49939ea9',
};

export const useKinde = () => {
  // hooks
  const {
    login: loginKinde,
    register: registerKinde,
    logout: logoutKinde,
  } = useKindeAuth();

  // methods
  const signIn = useCallback(
    (connectionID?: string, email?: string) => {
      loginKinde({
        authUrlParams: {
          connection_id: connectionID || ConnectionID.EMAIL,
          login_hint: email,
        },
        app_state: {},
      });
    },
    [loginKinde],
  );

  const register = useCallback(
    (connectionID?: string, email?: string, token?: string) => {
      registerKinde({
        authUrlParams: {
          connection_id: connectionID || ConnectionID.EMAIL,
          prompt: 'create',
          login_hint: email,
        },
        app_state: token ? { token } : {},
      });
    },
    [registerKinde],
  );

  return {
    signIn,
    signOut: logoutKinde,
    register,
    ConnectionID,
  };
};
