// react
import { useMemo } from 'react';

// hooks
import { useCareCircle, usePermissions } from 'hooks';

// store
import {
  useGetAllActionPlanCategoryQuery,
  useGetAllActionPlanFeedbackQuery,
  useGetAllActionPlanQuery,
  useGetMemberActionPlanQuery,
  useMarkActionCompleteMutation,
  useMarkActionDismissedMutation,
  useMarkActionFeedbackMutation,
} from 'store/api/actionPlan';
import { useLazyGetActionPlanSanityQuery } from 'store/api/cms';
import { getAccount } from 'store/slices/iamSlice';
import { useSelector } from 'store';

// models
import { ActionPlanItem } from '@karehero/models';

export const useActionPlan = () => {
  // hooks
  const account = useSelector(getAccount);
  // TODO: check if this should be primary
  const { primaryCareCircleMember: careCircleMember } = useCareCircle();
  const { data: actionPlanCategories } = useGetAllActionPlanCategoryQuery();
  const { data: memberActionPlanItems, refetch: refetchMemberActionPlanItems } =
    useGetMemberActionPlanQuery(undefined, {
      skip: account.accountRole.name !== 'Member',
    });
  const { data: allActionPlanItemsData } = useGetAllActionPlanQuery();
  const { data: actionPlanFeedback } = useGetAllActionPlanFeedbackQuery(
    undefined,
    {
      skip: account.accountRole.name !== 'Member',
    },
  );
  const [markActionComplete] = useMarkActionCompleteMutation();
  const [dismissAction] = useMarkActionDismissedMutation();
  const [markActionFeedback] = useMarkActionFeedbackMutation();
  const [getActionPlanSanity] = useLazyGetActionPlanSanityQuery();
  const { actionPlan } = usePermissions();

  // methods
  const actionPlanItems = useMemo<ActionPlanItem[]>(() => {
    const completedActionIds: { [key: string]: boolean } = {};
    const dismissedActionIds: { [key: string]: boolean } = {};
    const feedbackActionIds: { [key: string]: boolean } = {};

    careCircleMember?.completedActions?.forEach((action) => {
      completedActionIds[action.id] = true;
    });

    actionPlanFeedback?.forEach((action) => {
      feedbackActionIds[action.actionPlanItemID] = true;
    });

    const orderedActionPlanItemsData = memberActionPlanItems
      ? [...memberActionPlanItems].sort((a, b) => Math.sign(a.order - b.order))
      : [];

    return (
      orderedActionPlanItemsData.map((action) => {
        const category = actionPlanCategories?.find(
          (x) => x.id === action.actionPlanCategoryID,
        );

        memberActionPlanItems?.forEach((action) => {
          dismissedActionIds[action.id] = action.isDismiss || false;
        });

        return {
          ...action,
          subTitle: category?.title || '',
          color: category?.color || 'blue',
          to: `/action-plan/${action.id}`,
          icon: category?.icon || 'star',
          isComplete: completedActionIds[action.id] || false,
          isDismiss: dismissedActionIds[action.id],
          isFeedback: feedbackActionIds[action.id] || false,
          isModify: actionPlan.isModify,
          isFirst: orderedActionPlanItemsData.indexOf(action) === 0,
        };
      }) || []
    );
  }, [
    careCircleMember,
    actionPlanCategories,
    memberActionPlanItems,
    actionPlanFeedback,
    actionPlan,
  ]);

  const allActionPlanItems = useMemo<ActionPlanItem[]>(() => {
    const completedActionIds: { [key: string]: boolean } = {};
    const feedbackActionIds: { [key: string]: boolean } = {};

    careCircleMember?.completedActions?.forEach((action) => {
      completedActionIds[action.id] = true;
    });

    actionPlanFeedback?.forEach((action) => {
      feedbackActionIds[action.actionPlanItemID] = true;
    });

    const orderedActionPlanItemsData = allActionPlanItemsData
      ? [...allActionPlanItemsData].sort((a, b) => Math.sign(a.order - b.order))
      : [];

    // Track the first occurrence of each category
    const seenCategories = new Map<string, boolean>();

    const isFirstActionInCategory = (categoryId: string) => {
      if (seenCategories.has(categoryId)) {
        return false;
      }
      seenCategories.set(categoryId, true);
      return true;
    };

    return (
      orderedActionPlanItemsData?.map((action) => {
        const category = actionPlanCategories?.find(
          (x) => x.id === action.actionPlanCategoryID,
        );

        return {
          ...action,
          color: category?.color || 'blue',
          to: `/action-plan/${action.id}`,
          icon: action?.icon || category?.icon || 'star',
          isComplete: completedActionIds[action.id] || false,
          isFeedback: feedbackActionIds[action.id] || false,
          isModify: actionPlan.isModify,
          isFirst: isFirstActionInCategory(action.actionPlanCategoryID),
        };
      }) || []
    );
  }, [
    careCircleMember,
    actionPlanCategories,
    allActionPlanItemsData,
    actionPlanFeedback,
    actionPlan,
  ]);

  const actionPlanItemsPersonalized = useMemo<ActionPlanItem[]>(() => {
    return actionPlanItems?.filter((action) => !action.isDismiss);
  }, [actionPlanItems]);

  const actionPlanItemsTodo = useMemo<ActionPlanItem[]>(() => {
    return actionPlanItems?.filter(
      (action) => !action.isDismiss && !action.isComplete,
    );
  }, [actionPlanItems]);

  const markActionDismissed = useMemo(
    () => async (actionID: string) => {
      await dismissAction(actionID);
      refetchMemberActionPlanItems();
    },
    [dismissAction, refetchMemberActionPlanItems],
  );

  const computedActionPlanCategories = useMemo(
    () =>
      actionPlanCategories?.map((category) => ({
        ...category,
        actionPlanItems: allActionPlanItems.filter(
          (item) => item.actionPlanCategoryID === category.id,
        ),
        totalItems: allActionPlanItems.filter(
          (item) => item.actionPlanCategoryID === category.id,
        ).length,
        completedItems: allActionPlanItems.filter(
          (item) =>
            item.actionPlanCategoryID === category.id && item.isComplete,
        ).length,
      })),
    [actionPlanCategories, allActionPlanItems],
  );

  return {
    actionPlanItems,
    actionPlanItemsTodo,
    actionPlanItemsPersonalized,
    actionPlanCategories: computedActionPlanCategories,
    allActionPlanItems,
    getActionPlanSanity,
    markActionComplete,
    markActionDismissed,
    markActionFeedback,
  };
};
