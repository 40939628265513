import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { BackButton } from "../../molecules";
import { CardContainer } from "../../templates";
import { Button, Card, ProgressBar, Title } from "../../atoms";
import { EligibilityCalculator } from "..";
/**
 * RecipientProfileAttendanceAllowance shows the profile of the care recipient.
 */
export const RecipientProfileAttendanceAllowance = ({ progress, handleStart, limit, fields, answers, setAnswers, onUpdateCareProfile, onEligibilityStatusChange, isApple, }) => {
    // memos
    const isComplete = useMemo(() => {
        if (progress === undefined || limit === undefined)
            return false;
        return progress >= limit;
    }, [progress, limit]);
    const WeMakeClaimingEasy = memo(() => (_jsx(StyledCard, Object.assign({ color: 'green' }, { children: _jsx(CareProfileCardBody, { children: _jsxs(CardContent, { children: [_jsx(CardTitle, { children: "We make claiming easy:" }), _jsx(CardBody, { children: _jsxs("ol", { children: [_jsx("li", { children: "Complete the form below with 24/7 AI chatbot support." }), _jsxs("li", { children: ["We\u2019ll print your information onto", ' ', _jsx(Button, Object.assign({ ariaLabel: 'view gov form', variant: 'link', isInline: true, to: 'https://assets.publishing.service.gov.uk/media/65e5b1e63f694514a3036001/aa1-interactive-claim-form.pdf' }, { children: "this government form" })), ", and post it to you for signing."] }), _jsx("li", { children: "Post the form to the government using our free post envelope provided." })] }) })] }) }) }))));
    const ProgressCard = memo(() => (_jsx(StyledCard, Object.assign({ color: isComplete ? 'blue' : 'green' }, { children: _jsx(CareProfileCardWrapper, { children: _jsxs(CareProfileCardBody, { children: [_jsxs(CardContent, { children: [_jsx(CardTitle, { children: isComplete
                                    ? "Well done! You've completed the attendance allowance"
                                    : "You're doing great!" }), _jsx(CardBody, { children: isComplete
                                    ? 'You can now book a call back to discuss your attendance allowance form.'
                                    : 'Continue completing your attendance allowance application that could give you up to £5.6k a year to cover care costs' })] }), _jsx(ProgressBar, { label: 'Progress', limit: limit || 0, progress: progress }), _jsx(ActionGroup, { children: _jsx(Button, Object.assign({ ariaLabel: isComplete ? 'view attendance allowance' : 'continue claim', variant: isComplete ? 'secondary' : 'primary', onPress: handleStart, isFullWidth: true }, { children: isComplete ? 'View claim' : 'Continue claim' })) })] }) }) }))));
    const isStarted = useMemo(() => progress !== undefined && progress !== 0, [progress]);
    return (_jsxs(Body, Object.assign({ "$isStarted": isStarted }, { children: [_jsx(BackButton, {}), _jsx(Title, { children: "Claim Attendance Allowance" }), _jsx(HelperText, { children: "The government benefit Attendance Allowance could cover up to \u00A35.6k of your care costs per year." }), _jsxs(CardContainer, Object.assign({ maxColumns: 1, isPreventHeightMatch: true }, { children: [isStarted && _jsx(ProgressCard, {}), _jsx(Card, Object.assign({ isNoPadding: true }, { children: _jsxs(WrapperEligibilityCalculator, { children: [_jsx(EligibilityCalculator, { fields: fields, answers: answers, setAnswers: setAnswers, onEligibilityStatusChange: onEligibilityStatusChange, onUpdateCareProfile: onUpdateCareProfile }), _jsx(Banner, { children: "Eligibility calculator" })] }) })), !isStarted && (_jsxs(_Fragment, { children: [_jsx(WeMakeClaimingEasy, {}), _jsx(StartNowWrapper, Object.assign({ "$isApple": isApple }, { children: _jsx(StyledButton, Object.assign({ ariaLabel: 'start now', variant: 'primary', to: '/attendance-allowance/attendance-allowance-general-information' }, { children: "Start Now" })) }))] }))] }))] })));
};
const Body = styled.div `
  position: relative;
  padding-bottom: ${(props) => props.theme.spacing[96]};
  ${(props) => props.$isStarted &&
    css `
      padding-bottom: ${props.theme.spacing[24]};
    `}
`;
const HelperText = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  padding-bottom: ${(props) => props.theme.spacing[36]};
`;
const StyledCard = styled(Card) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing[32]};
  align-items: flex-start;
  color: ${(props) => props.theme.color.neutral[80]};
  border-radius: 8px;
`;
const CareProfileCardBody = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${(props) => props.theme.spacing[32]};
  flex: 1;
`;
const CareProfileCardWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const CardContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};
`;
const CardTitle = styled.div `
  font: ${(props) => props.theme.typography.heading['3xl']};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const CardBody = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[100]};

  & > ul {
    margin-bottom: 0;
  }
`;
const ActionGroup = styled.div `
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Banner = styled.div `
  background: ${(props) => props.theme.color.primary[80]};
  color: ${(props) => props.theme.color.neutral[10]};
  font: ${(props) => props.theme.typography.body.xxs.medium};
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[8]};
  position: absolute;
  top: 0;
  left: 3%;
  transform: translateX(-3%);
  border-radius: 0 0 6px 6px;
`;
const WrapperEligibilityCalculator = styled.div `
  position: relative;
  padding-top: ${(props) => props.theme.spacing[2]};
`;
const StartNowWrapper = styled.div `
  position: fixed;
  bottom: 67px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.color.neutral[10]};
  border-top: 1px solid ${(props) => props.theme.color.neutral[50]};
  padding: ${(props) => props.theme.spacing[16]};

  ${(props) => props.theme.breakpoint.sm(css `
      bottom: 0;
    `)}

  ${(props) => props.$isApple &&
    css `
      bottom: 88px;
    `}
`;
const StyledButton = styled(Button) `
  width: 100%;
  max-width: 350px;
`;
RecipientProfileAttendanceAllowance.displayName =
    'RecipientProfileAttendanceAllowance';
export default RecipientProfileAttendanceAllowance;
