// react
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// models
import { FieldType } from '@karehero/models';

// components
import { Button, createFieldHelper, EditorPage } from '@karehero/llama';

// store
import { Account, CareCircleMember } from '@karehero/models';
import { useCreateMemberMutation } from 'store/api/careCircle';

export const fields = createFieldHelper<Account>({
  firstName: {
    label: 'First Name',
    field: {
      type: FieldType.Text,
    },
  },
  lastName: {
    label: 'Last Name',
    field: {
      type: FieldType.Text,
    },
  },
  email: {
    label: 'Email',
    field: {
      type: FieldType.Text,
    },
  },
});

/**
 * NewCareCircleMember is the dashboard page.
 */
const NewCareCircleMember = () => {
  // state
  const [careCircleMember, setCareCircleMember] = useState<Partial<Account>>(
    {},
  );
  const { careCircleId } = useParams();

  // hooks
  const navigate = useNavigate();
  const [createCareCircleMember] = useCreateMemberMutation();

  // methods
  const handleSave = useCallback(async () => {
    if (!careCircleId) return;
    const res = await createCareCircleMember({
      careCircleID: careCircleId,
      account: careCircleMember as Account,
    } as CareCircleMember);
    if (!('data' in res)) {
      return;
    }

    navigate(-1);
  }, [careCircleMember, createCareCircleMember, navigate, careCircleId]);

  return (
    <>
      <Button
        ariaLabel='back'
        variant='link'
        iconLeft={'chevron-left'}
        onPress={() => navigate(-1)}
      >
        Back
      </Button>
      <EditorPage
        title={'New Care Circle Member'}
        fields={fields}
        value={careCircleMember}
        onChange={setCareCircleMember}
        saveLabel='Add Member'
        cancelLabel='Close'
        onSave={handleSave}
        onCancel={() => navigate(-1)}
      />
    </>
  );
};

export default NewCareCircleMember;
